// =======================================
//     List of variables for layout
// =======================================
:root {
  // body
  --#{$variable-prefix}body-bg: #{$body-bg};
  --bs-body-bg-rgb: #{to-rgb($body-bg)};

  --pc-heading-color: #{$gray-800};
  --pc-active-background: #{$gray-200};

  // Navbar
  --pc-sidebar-background: #fff;
  --pc-sidebar-color: #39465f;
  --pc-sidebar-color-rgb: #{to-rgb(#39465f)};
  --pc-sidebar-submenu-border-color: var(--bs-gray-300);
  --pc-sidebar-active-color: #{$primary};
  --pc-sidebar-shadow: 0px 0px 24px rgba(27, 46, 94, 0.05);
  --pc-sidebar-caption-color: var(--pc-heading-color);
  --pc-sidebar-border: none;

  // header
  --pc-header-background: rgba(#{var(--bs-body-bg-rgb)}, 0.7);
  --pc-header-color: #{#39465f};
  --pc-header-shadow: none;

  // card
  --pc-card-box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12);

  // horizontal menu
  --pc-header-submenu-background: #{$white};
  --pc-header-submenu-color: #{#39465f};
}
[data-pc-sidebar-theme='dark'] {
  // Navbar
  --pc-sidebar-background: #1d2630;
  --pc-sidebar-color: #ffffff;
  --pc-sidebar-color-rgb: #{to-rgb(#ffffff)};
  --pc-sidebar-submenu-border-color: var(--bs-gray-600);
  --pc-sidebar-caption-color: #748892;
}
[class='layout-3'],
[class='layout-nested'] {
  --pc-sidebar-background: #1d2630;
  --pc-sidebar-color: #ffffff;
  --pc-sidebar-color-rgb: #{to-rgb(#ffffff)};
  --pc-sidebar-submenu-border-color: var(--bs-gray-600);
  --pc-sidebar-caption-color: #748892;

  --pc-header-background: #fff;
  --pc-header-color: #{#39465f};
}
[class='layout-3'] {
  --pc-header-shadow: none;
}
[class='layout-nested'] {
  --pc-sidebar-border: 1px solid var(--pc-sidebar-submenu-border-color);
  --pc-header-shadow: var(--pc-sidebar-shadow);
}
[class='layout-modern'] {
  --pc-header-background: #{$dark};
  --pc-header-color: #fff;
  --pc-header-shadow: var(--pc-sidebar-shadow);
}
[class='layout-advance'] {
  --pc-header-background: var(--bs-primary);
  --pc-header-color: #fff;
  --pc-header-shadow: none;
}
[class='layout-extended'] {
  --pc-header-background: #{$dark};
  --pc-header-color: #fff;
  --pc-header-shadow: var(--pc-sidebar-shadow);
}

$header-height: 74px;
$sidebar-width: 280px;
$sidebar-collapsed-width: 100px;
$sidebar-collapsed-active-width: 300px;
$sidebar-tab-width: 75px;
$sidebar-tab-navbar-width: 320px;

$sidebar-compact-width: 180px;

// horizontal menu
$topbar-height: 60px;

$soft-bg-level: -90%;
// =====================================
//      Variables for dark layouts
// =====================================
$dark-layout-color: $gray-900;

// =====================================
//      Variables for bootstrap color
// =====================================

$blue: $blue-500;
$secondary: #39465f;
$indigo: $indigo-500;
$purple: $purple-500;
$pink: $pink-500;
$red: $red-500;
$orange: $orange-500;
$yellow: $yellow-500;
$green: $green-500;
$teal: $teal-500;
$cyan: $cyan-500;

$primary-text: $blue-600;
$secondary-text: #39465f;
$success-text: $green-600;
$info-text: $cyan-700;
$warning-text: $yellow-700;
$danger-text: $red-600;
$light-text: #39465f;
$dark-text: $gray-700;

$primary-bg-subtle: $blue-100;
$secondary-bg-subtle: $gray-100;
$success-bg-subtle: $green-100;
$info-bg-subtle: $cyan-100;
$warning-bg-subtle: $yellow-100;
$danger-bg-subtle: $red-100;
$light-bg-subtle: mix($gray-100, $white);
$dark-bg-subtle: $gray-400;

$primary-border-subtle: $blue-200;
$secondary-border-subtle: $gray-200;
$success-border-subtle: $green-200;
$info-border-subtle: $cyan-200;
$warning-border-subtle: $yellow-200;
$danger-border-subtle: $red-200;
$light-border-subtle: $gray-200;
$dark-border-subtle: $gray-500;
$brand-colors: (
  'brand-color-1': $brand-color1,
  'brand-color-2': $brand-color2,
  'brand-color-3': $brand-color3,
  'brand-color-4': $brand-color4
);
$preset-colors: (
  preset-1: (
    primary: $blue-500
  ),
  preset-2: (
    primary: $indigo-500
  ),
  preset-3: (
    primary: $purple-500
  ),
  preset-4: (
    primary: $pink-500
  ),
  preset-5: (
    primary: $red-500
  ),
  preset-6: (
    primary: $orange-500
  ),
  preset-7: (
    primary: $yellow-500
  ),
  preset-8: (
    primary: $green-500
  ),
  preset-9: (
    primary: $teal-500
  ),
  preset-10: (
    primary: $cyan-500
  ),
  preset-11: (
    primary: $dark
  )
);
