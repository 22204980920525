/**======================================================================
=========================================================================
Template Name: Light Able - Bootstrap Admin Template
Author: phoenixcoded
Support: https://phoenixcoded.authordesk.app
File: style.css
=========================================================================
=================================================================================== */
body {
  font-feature-settings: "salt";
}

h1,
h2 {
  font-weight: 700;
}

:root {
  --bs-body-bg: #f4f7fa;
  --bs-body-bg-rgb: 244, 247, 250;
  --pc-heading-color: #1d2630;
  --pc-active-background: #f3f5f7;
  --pc-sidebar-background: #fff;
  --pc-sidebar-color: #39465f;
  --pc-sidebar-color-rgb: 57, 70, 95;
  --pc-sidebar-submenu-border-color: var(--bs-gray-300);
  --pc-sidebar-active-color: #0d6efd;
  --pc-sidebar-shadow: 0px 0px 24px rgba(27, 46, 94, 0.05);
  --pc-sidebar-caption-color: var(--pc-heading-color);
  --pc-sidebar-border: none;
  --pc-header-background: rgba(var(--bs-body-bg-rgb), 0.7);
  --pc-header-color: #39465f;
  --pc-header-shadow: none;
  --pc-card-box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12);
  --pc-header-submenu-background: #ffffff;
  --pc-header-submenu-color: #39465f;
}

[data-pc-sidebar-theme=dark] {
  --pc-sidebar-background: #1d2630;
  --pc-sidebar-color: #ffffff;
  --pc-sidebar-color-rgb: 255, 255, 255;
  --pc-sidebar-submenu-border-color: var(--bs-gray-600);
  --pc-sidebar-caption-color: #748892;
}

[class=layout-3],
[class=layout-nested] {
  --pc-sidebar-background: #1d2630;
  --pc-sidebar-color: #ffffff;
  --pc-sidebar-color-rgb: 255, 255, 255;
  --pc-sidebar-submenu-border-color: var(--bs-gray-600);
  --pc-sidebar-caption-color: #748892;
  --pc-header-background: #fff;
  --pc-header-color: #39465f;
}

[class=layout-3] {
  --pc-header-shadow: none;
}

[class=layout-nested] {
  --pc-sidebar-border: 1px solid var(--pc-sidebar-submenu-border-color);
  --pc-header-shadow: var(--pc-sidebar-shadow);
}

[class=layout-modern] {
  --pc-header-background: #131920;
  --pc-header-color: #fff;
  --pc-header-shadow: var(--pc-sidebar-shadow);
}

[class=layout-advance] {
  --pc-header-background: var(--bs-primary);
  --pc-header-color: #fff;
  --pc-header-shadow: none;
}

[class=layout-extended] {
  --pc-header-background: #131920;
  --pc-header-color: #fff;
  --pc-header-shadow: var(--pc-sidebar-shadow);
}

[data-pc-preset=preset-1] {
  --pc-sidebar-active-color: #04a9f5;
  --bs-blue: #04a9f5;
  --bs-primary: #04a9f5;
  --bs-primary-rgb: 4, 169, 245;
  --bs-primary-light: #e6f6fe;
  --bs-link-color: #04a9f5;
  --bs-link-color-rgb: 4, 169, 245;
  --bs-link-hover-color: #0387c4;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 4, 169, 245;
}
[data-pc-preset=preset-1] .bg-light-primary {
  background: #e6f6fe;
  color: #04a9f5;
}
[data-pc-preset=preset-1] .link-primary {
  color: #04a9f5 !important;
}
[data-pc-preset=preset-1] .link-primary:hover, [data-pc-preset=preset-1] .link-primary:focus {
  color: #0387c4 !important;
}
[data-pc-preset=preset-1] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #04a9f5;
  --bs-btn-border-color: #04a9f5;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #0390d0;
  --bs-btn-hover-border-color: #0387c4;
  --bs-btn-focus-shadow-rgb: 42, 182, 247;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #0387c4;
  --bs-btn-active-border-color: #037fb8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #04a9f5;
  --bs-btn-disabled-border-color: #04a9f5;
}
[data-pc-preset=preset-1] .btn-link {
  --bs-btn-color: #04a9f5;
  --bs-btn-hover-color: #0387c4;
  --bs-btn-active-color: #0387c4;
}
[data-pc-preset=preset-1] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(4, 169, 245, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-1] .accordion {
  --bs-accordion-btn-focus-border-color: #04a9f5;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(4, 169, 245, 0.25);
  --bs-accordion-active-color: #04a9f5;
  --bs-accordion-active-bg: #e6f6fe;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2304a9f5'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .alert-primary {
  --bs-alert-color: #026593;
  --bs-alert-bg: #cdeefd;
  --bs-alert-border-color: #b4e5fc;
  --bs-alert-link-color: #025176;
}
[data-pc-preset=preset-1] .list-group {
  --bs-list-group-active-bg: #04a9f5;
  --bs-list-group-active-border-color: #04a9f5;
}
[data-pc-preset=preset-1] .list-group-item-primary {
  color: #026593;
  background-color: #cdeefd;
}
[data-pc-preset=preset-1] .nav {
  --bs-nav-link-hover-color: #0387c4;
}
[data-pc-preset=preset-1] .nav-pills {
  --bs-nav-pills-link-active-bg: #04a9f5;
}
[data-pc-preset=preset-1] .pagination {
  --bs-pagination-hover-color: #0387c4;
  --bs-pagination-focus-color: #0387c4;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(4, 169, 245, 0.25);
  --bs-pagination-active-bg: #04a9f5;
  --bs-pagination-active-border-color: #04a9f5;
}
[data-pc-preset=preset-1] .progress {
  --bs-progress-bar-bg: #04a9f5;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-primary:checked {
  border-color: #04a9f5;
  background-color: #04a9f5;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:checked {
  border-color: #e6f6fe;
  background-color: #e6f6fe;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%2304a9f5' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%2304a9f5'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-1] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(4, 169, 245, 0.25);
  border-color: #04a9f5;
}
[data-pc-preset=preset-1] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2304a9f5'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .btn-light-primary {
  background: #e6f6fe;
  color: #04a9f5;
  border-color: #e6f6fe;
}
[data-pc-preset=preset-1] .btn-light-primary .material-icons-two-tone {
  background-color: #04a9f5;
}
[data-pc-preset=preset-1] .btn-light-primary:hover {
  background: #04a9f5;
  color: #fff;
  border-color: #04a9f5;
}
[data-pc-preset=preset-1] .btn-light-primary.focus, [data-pc-preset=preset-1] .btn-light-primary:focus {
  background: #04a9f5;
  color: #fff;
  border-color: #04a9f5;
}
[data-pc-preset=preset-1] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-1] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-1] .btn-light-primary.dropdown-toggle {
  background: #04a9f5;
  color: #fff;
  border-color: #04a9f5;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-1] .btn-check:checked + .btn-light-primary {
  background: #04a9f5;
  color: #fff;
  border-color: #04a9f5;
}
[data-pc-preset=preset-1] .btn-link-primary {
  background: transparent;
  color: #04a9f5;
  border-color: transparent;
}
[data-pc-preset=preset-1] .btn-link-primary .material-icons-two-tone {
  background-color: #04a9f5;
}
[data-pc-preset=preset-1] .btn-link-primary:hover {
  background: #e6f6fe;
  color: #04a9f5;
  border-color: #e6f6fe;
}
[data-pc-preset=preset-1] .btn-link-primary.focus, [data-pc-preset=preset-1] .btn-link-primary:focus {
  background: #e6f6fe;
  color: #04a9f5;
  border-color: #e6f6fe;
}
[data-pc-preset=preset-1] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-1] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-1] .btn-link-primary.dropdown-toggle {
  background: #e6f6fe;
  color: #04a9f5;
  border-color: #e6f6fe;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-1] .btn-check:checked + .btn-link-primary {
  background: #e6f6fe;
  color: #04a9f5;
  border-color: #e6f6fe;
}
[data-pc-preset=preset-1] .slider-selection {
  background-image: linear-gradient(to bottom, #7cd4fd 0, #7cd4fd 100%);
}
[data-pc-preset=preset-1] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #63ccfc 0, #63ccfc 100%);
}
[data-pc-preset=preset-1] .swal-button:not([disabled]):hover {
  background-color: #0498dc;
}
[data-pc-preset=preset-1] .swal-button:active {
  background-color: #0498dc;
}
[data-pc-preset=preset-1] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(4, 169, 245, 0.29);
}
[data-pc-preset=preset-1] .swal-content__input:focus {
  border-color: rgba(4, 169, 245, 0.29);
}
[data-pc-preset=preset-1] .swal-content__textarea:focus {
  border-color: rgba(4, 169, 245, 0.29);
}
[data-pc-preset=preset-1] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(4, 169, 245, 0.4) !important;
}
[data-pc-preset=preset-1] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #63ccfc 0, #63ccfc 100%);
}
[data-pc-preset=preset-1] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #04a9f5;
  --bs-table-border-color: #1db2f6;
  --bs-table-striped-bg: #11adf6;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #1db2f6;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #09abf5;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-2] {
  --pc-sidebar-active-color: #6610f2;
  --bs-blue: #6610f2;
  --bs-primary: #6610f2;
  --bs-primary-rgb: 102, 16, 242;
  --bs-primary-light: #f0e7fe;
  --bs-link-color: #6610f2;
  --bs-link-color-rgb: 102, 16, 242;
  --bs-link-hover-color: #520dc2;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 102, 16, 242;
}
[data-pc-preset=preset-2] .bg-light-primary {
  background: #f0e7fe;
  color: #6610f2;
}
[data-pc-preset=preset-2] .link-primary {
  color: #6610f2 !important;
}
[data-pc-preset=preset-2] .link-primary:hover, [data-pc-preset=preset-2] .link-primary:focus {
  color: #520dc2 !important;
}
[data-pc-preset=preset-2] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #6610f2;
  --bs-btn-border-color: #6610f2;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #570ece;
  --bs-btn-hover-border-color: #520dc2;
  --bs-btn-focus-shadow-rgb: 125, 52, 244;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #520dc2;
  --bs-btn-active-border-color: #4d0cb6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #6610f2;
  --bs-btn-disabled-border-color: #6610f2;
}
[data-pc-preset=preset-2] .btn-link {
  --bs-btn-color: #6610f2;
  --bs-btn-hover-color: #520dc2;
  --bs-btn-active-color: #520dc2;
}
[data-pc-preset=preset-2] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(102, 16, 242, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-2] .accordion {
  --bs-accordion-btn-focus-border-color: #6610f2;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.25);
  --bs-accordion-active-color: #6610f2;
  --bs-accordion-active-bg: #f0e7fe;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236610f2'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .alert-primary {
  --bs-alert-color: #3d0a91;
  --bs-alert-bg: #e0cffc;
  --bs-alert-border-color: #d1b7fb;
  --bs-alert-link-color: #310874;
}
[data-pc-preset=preset-2] .list-group {
  --bs-list-group-active-bg: #6610f2;
  --bs-list-group-active-border-color: #6610f2;
}
[data-pc-preset=preset-2] .list-group-item-primary {
  color: #3d0a91;
  background-color: #e0cffc;
}
[data-pc-preset=preset-2] .nav {
  --bs-nav-link-hover-color: #520dc2;
}
[data-pc-preset=preset-2] .nav-pills {
  --bs-nav-pills-link-active-bg: #6610f2;
}
[data-pc-preset=preset-2] .pagination {
  --bs-pagination-hover-color: #520dc2;
  --bs-pagination-focus-color: #520dc2;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.25);
  --bs-pagination-active-bg: #6610f2;
  --bs-pagination-active-border-color: #6610f2;
}
[data-pc-preset=preset-2] .progress {
  --bs-progress-bar-bg: #6610f2;
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-primary:checked {
  border-color: #6610f2;
  background-color: #6610f2;
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:checked {
  border-color: #f0e7fe;
  background-color: #f0e7fe;
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%236610f2' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%236610f2'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-2] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.25);
  border-color: #6610f2;
}
[data-pc-preset=preset-2] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%236610f2'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .btn-light-primary {
  background: #f0e7fe;
  color: #6610f2;
  border-color: #f0e7fe;
}
[data-pc-preset=preset-2] .btn-light-primary .material-icons-two-tone {
  background-color: #6610f2;
}
[data-pc-preset=preset-2] .btn-light-primary:hover {
  background: #6610f2;
  color: #fff;
  border-color: #6610f2;
}
[data-pc-preset=preset-2] .btn-light-primary.focus, [data-pc-preset=preset-2] .btn-light-primary:focus {
  background: #6610f2;
  color: #fff;
  border-color: #6610f2;
}
[data-pc-preset=preset-2] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-2] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-2] .btn-light-primary.dropdown-toggle {
  background: #6610f2;
  color: #fff;
  border-color: #6610f2;
}
[data-pc-preset=preset-2] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-2] .btn-check:checked + .btn-light-primary {
  background: #6610f2;
  color: #fff;
  border-color: #6610f2;
}
[data-pc-preset=preset-2] .btn-link-primary {
  background: transparent;
  color: #6610f2;
  border-color: transparent;
}
[data-pc-preset=preset-2] .btn-link-primary .material-icons-two-tone {
  background-color: #6610f2;
}
[data-pc-preset=preset-2] .btn-link-primary:hover {
  background: #f0e7fe;
  color: #6610f2;
  border-color: #f0e7fe;
}
[data-pc-preset=preset-2] .btn-link-primary.focus, [data-pc-preset=preset-2] .btn-link-primary:focus {
  background: #f0e7fe;
  color: #6610f2;
  border-color: #f0e7fe;
}
[data-pc-preset=preset-2] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-2] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-2] .btn-link-primary.dropdown-toggle {
  background: #f0e7fe;
  color: #6610f2;
  border-color: #f0e7fe;
}
[data-pc-preset=preset-2] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-2] .btn-check:checked + .btn-link-primary {
  background: #f0e7fe;
  color: #6610f2;
  border-color: #f0e7fe;
}
[data-pc-preset=preset-2] .slider-selection {
  background-image: linear-gradient(to bottom, #b389f9 0, #b389f9 100%);
}
[data-pc-preset=preset-2] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #a471f7 0, #a471f7 100%);
}
[data-pc-preset=preset-2] .swal-button:not([disabled]):hover {
  background-color: #5b0cdd;
}
[data-pc-preset=preset-2] .swal-button:active {
  background-color: #5b0cdd;
}
[data-pc-preset=preset-2] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(102, 16, 242, 0.29);
}
[data-pc-preset=preset-2] .swal-content__input:focus {
  border-color: rgba(102, 16, 242, 0.29);
}
[data-pc-preset=preset-2] .swal-content__textarea:focus {
  border-color: rgba(102, 16, 242, 0.29);
}
[data-pc-preset=preset-2] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(102, 16, 242, 0.4) !important;
}
[data-pc-preset=preset-2] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #a471f7 0, #a471f7 100%);
}
[data-pc-preset=preset-2] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #6610f2;
  --bs-table-border-color: #7528f3;
  --bs-table-striped-bg: #6e1cf3;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #7528f3;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #6915f2;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-3] {
  --pc-sidebar-active-color: #673ab7;
  --bs-blue: #673ab7;
  --bs-primary: #673ab7;
  --bs-primary-rgb: 103, 58, 183;
  --bs-primary-light: #f0ebf8;
  --bs-link-color: #673ab7;
  --bs-link-color-rgb: 103, 58, 183;
  --bs-link-hover-color: #522e92;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 103, 58, 183;
}
[data-pc-preset=preset-3] .bg-light-primary {
  background: #f0ebf8;
  color: #673ab7;
}
[data-pc-preset=preset-3] .link-primary {
  color: #673ab7 !important;
}
[data-pc-preset=preset-3] .link-primary:hover, [data-pc-preset=preset-3] .link-primary:focus {
  color: #522e92 !important;
}
[data-pc-preset=preset-3] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #673ab7;
  --bs-btn-border-color: #673ab7;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #58319c;
  --bs-btn-hover-border-color: #522e92;
  --bs-btn-focus-shadow-rgb: 126, 88, 194;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #522e92;
  --bs-btn-active-border-color: #4d2c89;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #673ab7;
  --bs-btn-disabled-border-color: #673ab7;
}
[data-pc-preset=preset-3] .btn-link {
  --bs-btn-color: #673ab7;
  --bs-btn-hover-color: #522e92;
  --bs-btn-active-color: #522e92;
}
[data-pc-preset=preset-3] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(103, 58, 183, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-3] .accordion {
  --bs-accordion-btn-focus-border-color: #673ab7;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(103, 58, 183, 0.25);
  --bs-accordion-active-color: #673ab7;
  --bs-accordion-active-bg: #f0ebf8;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23673ab7'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .alert-primary {
  --bs-alert-color: #3e236e;
  --bs-alert-bg: #e1d8f1;
  --bs-alert-border-color: #d1c4e9;
  --bs-alert-link-color: #321c58;
}
[data-pc-preset=preset-3] .list-group {
  --bs-list-group-active-bg: #673ab7;
  --bs-list-group-active-border-color: #673ab7;
}
[data-pc-preset=preset-3] .list-group-item-primary {
  color: #3e236e;
  background-color: #e1d8f1;
}
[data-pc-preset=preset-3] .nav {
  --bs-nav-link-hover-color: #522e92;
}
[data-pc-preset=preset-3] .nav-pills {
  --bs-nav-pills-link-active-bg: #673ab7;
}
[data-pc-preset=preset-3] .pagination {
  --bs-pagination-hover-color: #522e92;
  --bs-pagination-focus-color: #522e92;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(103, 58, 183, 0.25);
  --bs-pagination-active-bg: #673ab7;
  --bs-pagination-active-border-color: #673ab7;
}
[data-pc-preset=preset-3] .progress {
  --bs-progress-bar-bg: #673ab7;
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-primary:checked {
  border-color: #673ab7;
  background-color: #673ab7;
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:checked {
  border-color: #f0ebf8;
  background-color: #f0ebf8;
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23673ab7' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23673ab7'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-3] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(103, 58, 183, 0.25);
  border-color: #673ab7;
}
[data-pc-preset=preset-3] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23673ab7'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .btn-light-primary {
  background: #f0ebf8;
  color: #673ab7;
  border-color: #f0ebf8;
}
[data-pc-preset=preset-3] .btn-light-primary .material-icons-two-tone {
  background-color: #673ab7;
}
[data-pc-preset=preset-3] .btn-light-primary:hover {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-preset=preset-3] .btn-light-primary.focus, [data-pc-preset=preset-3] .btn-light-primary:focus {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-preset=preset-3] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-3] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-3] .btn-light-primary.dropdown-toggle {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-preset=preset-3] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-3] .btn-check:checked + .btn-light-primary {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-preset=preset-3] .btn-link-primary {
  background: transparent;
  color: #673ab7;
  border-color: transparent;
}
[data-pc-preset=preset-3] .btn-link-primary .material-icons-two-tone {
  background-color: #673ab7;
}
[data-pc-preset=preset-3] .btn-link-primary:hover {
  background: #f0ebf8;
  color: #673ab7;
  border-color: #f0ebf8;
}
[data-pc-preset=preset-3] .btn-link-primary.focus, [data-pc-preset=preset-3] .btn-link-primary:focus {
  background: #f0ebf8;
  color: #673ab7;
  border-color: #f0ebf8;
}
[data-pc-preset=preset-3] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-3] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-3] .btn-link-primary.dropdown-toggle {
  background: #f0ebf8;
  color: #673ab7;
  border-color: #f0ebf8;
}
[data-pc-preset=preset-3] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-3] .btn-check:checked + .btn-link-primary {
  background: #f0ebf8;
  color: #673ab7;
  border-color: #f0ebf8;
}
[data-pc-preset=preset-3] .slider-selection {
  background-image: linear-gradient(to bottom, #ae94dd 0, #ae94dd 100%);
}
[data-pc-preset=preset-3] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #9f80d7 0, #9f80d7 100%);
}
[data-pc-preset=preset-3] .swal-button:not([disabled]):hover {
  background-color: #5c34a4;
}
[data-pc-preset=preset-3] .swal-button:active {
  background-color: #5c34a4;
}
[data-pc-preset=preset-3] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(103, 58, 183, 0.29);
}
[data-pc-preset=preset-3] .swal-content__input:focus {
  border-color: rgba(103, 58, 183, 0.29);
}
[data-pc-preset=preset-3] .swal-content__textarea:focus {
  border-color: rgba(103, 58, 183, 0.29);
}
[data-pc-preset=preset-3] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(103, 58, 183, 0.4) !important;
}
[data-pc-preset=preset-3] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #9f80d7 0, #9f80d7 100%);
}
[data-pc-preset=preset-3] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #673ab7;
  --bs-table-border-color: #764ebe;
  --bs-table-striped-bg: #6f44bb;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #764ebe;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #6a3eb8;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-4] {
  --pc-sidebar-active-color: #e83e8c;
  --bs-blue: #e83e8c;
  --bs-primary: #e83e8c;
  --bs-primary-rgb: 232, 62, 140;
  --bs-primary-light: #fdecf4;
  --bs-link-color: #e83e8c;
  --bs-link-color-rgb: 232, 62, 140;
  --bs-link-hover-color: #ba3270;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 232, 62, 140;
}
[data-pc-preset=preset-4] .bg-light-primary {
  background: #fdecf4;
  color: #e83e8c;
}
[data-pc-preset=preset-4] .link-primary {
  color: #e83e8c !important;
}
[data-pc-preset=preset-4] .link-primary:hover, [data-pc-preset=preset-4] .link-primary:focus {
  color: #ba3270 !important;
}
[data-pc-preset=preset-4] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #e83e8c;
  --bs-btn-border-color: #e83e8c;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #c53577;
  --bs-btn-hover-border-color: #ba3270;
  --bs-btn-focus-shadow-rgb: 235, 91, 157;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ba3270;
  --bs-btn-active-border-color: #ae2f69;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #e83e8c;
  --bs-btn-disabled-border-color: #e83e8c;
}
[data-pc-preset=preset-4] .btn-link {
  --bs-btn-color: #e83e8c;
  --bs-btn-hover-color: #ba3270;
  --bs-btn-active-color: #ba3270;
}
[data-pc-preset=preset-4] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(232, 62, 140, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-4] .accordion {
  --bs-accordion-btn-focus-border-color: #e83e8c;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.25);
  --bs-accordion-active-color: #e83e8c;
  --bs-accordion-active-bg: #fdecf4;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e83e8c'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .alert-primary {
  --bs-alert-color: #8b2554;
  --bs-alert-bg: #fad8e8;
  --bs-alert-border-color: #f8c5dd;
  --bs-alert-link-color: #6f1e43;
}
[data-pc-preset=preset-4] .list-group {
  --bs-list-group-active-bg: #e83e8c;
  --bs-list-group-active-border-color: #e83e8c;
}
[data-pc-preset=preset-4] .list-group-item-primary {
  color: #8b2554;
  background-color: #fad8e8;
}
[data-pc-preset=preset-4] .nav {
  --bs-nav-link-hover-color: #ba3270;
}
[data-pc-preset=preset-4] .nav-pills {
  --bs-nav-pills-link-active-bg: #e83e8c;
}
[data-pc-preset=preset-4] .pagination {
  --bs-pagination-hover-color: #ba3270;
  --bs-pagination-focus-color: #ba3270;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.25);
  --bs-pagination-active-bg: #e83e8c;
  --bs-pagination-active-border-color: #e83e8c;
}
[data-pc-preset=preset-4] .progress {
  --bs-progress-bar-bg: #e83e8c;
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-primary:checked {
  border-color: #e83e8c;
  background-color: #e83e8c;
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:checked {
  border-color: #fdecf4;
  background-color: #fdecf4;
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23e83e8c' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23e83e8c'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-4] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.25);
  border-color: #e83e8c;
}
[data-pc-preset=preset-4] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23e83e8c'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .btn-light-primary {
  background: #fdecf4;
  color: #e83e8c;
  border-color: #fdecf4;
}
[data-pc-preset=preset-4] .btn-light-primary .material-icons-two-tone {
  background-color: #e83e8c;
}
[data-pc-preset=preset-4] .btn-light-primary:hover {
  background: #e83e8c;
  color: #fff;
  border-color: #e83e8c;
}
[data-pc-preset=preset-4] .btn-light-primary.focus, [data-pc-preset=preset-4] .btn-light-primary:focus {
  background: #e83e8c;
  color: #fff;
  border-color: #e83e8c;
}
[data-pc-preset=preset-4] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-4] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-4] .btn-light-primary.dropdown-toggle {
  background: #e83e8c;
  color: #fff;
  border-color: #e83e8c;
}
[data-pc-preset=preset-4] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-4] .btn-check:checked + .btn-light-primary {
  background: #e83e8c;
  color: #fff;
  border-color: #e83e8c;
}
[data-pc-preset=preset-4] .btn-link-primary {
  background: transparent;
  color: #e83e8c;
  border-color: transparent;
}
[data-pc-preset=preset-4] .btn-link-primary .material-icons-two-tone {
  background-color: #e83e8c;
}
[data-pc-preset=preset-4] .btn-link-primary:hover {
  background: #fdecf4;
  color: #e83e8c;
  border-color: #fdecf4;
}
[data-pc-preset=preset-4] .btn-link-primary.focus, [data-pc-preset=preset-4] .btn-link-primary:focus {
  background: #fdecf4;
  color: #e83e8c;
  border-color: #fdecf4;
}
[data-pc-preset=preset-4] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-4] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-4] .btn-link-primary.dropdown-toggle {
  background: #fdecf4;
  color: #e83e8c;
  border-color: #fdecf4;
}
[data-pc-preset=preset-4] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-4] .btn-check:checked + .btn-link-primary {
  background: #fdecf4;
  color: #e83e8c;
  border-color: #fdecf4;
}
[data-pc-preset=preset-4] .slider-selection {
  background-image: linear-gradient(to bottom, #f6b0d0 0, #f6b0d0 100%);
}
[data-pc-preset=preset-4] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #f399c2 0, #f399c2 100%);
}
[data-pc-preset=preset-4] .swal-button:not([disabled]):hover {
  background-color: #e5277e;
}
[data-pc-preset=preset-4] .swal-button:active {
  background-color: #e5277e;
}
[data-pc-preset=preset-4] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(232, 62, 140, 0.29);
}
[data-pc-preset=preset-4] .swal-content__input:focus {
  border-color: rgba(232, 62, 140, 0.29);
}
[data-pc-preset=preset-4] .swal-content__textarea:focus {
  border-color: rgba(232, 62, 140, 0.29);
}
[data-pc-preset=preset-4] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(232, 62, 140, 0.4) !important;
}
[data-pc-preset=preset-4] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #f399c2 0, #f399c2 100%);
}
[data-pc-preset=preset-4] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #e83e8c;
  --bs-table-border-color: #ea5198;
  --bs-table-striped-bg: #e94892;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #ea5198;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #e8428e;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-5] {
  --pc-sidebar-active-color: #f44236;
  --bs-blue: #f44236;
  --bs-primary: #f44236;
  --bs-primary-rgb: 244, 66, 54;
  --bs-primary-light: #feeceb;
  --bs-link-color: #f44236;
  --bs-link-color-rgb: 244, 66, 54;
  --bs-link-hover-color: #c3352b;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 244, 66, 54;
}
[data-pc-preset=preset-5] .bg-light-primary {
  background: #feeceb;
  color: #f44236;
}
[data-pc-preset=preset-5] .link-primary {
  color: #f44236 !important;
}
[data-pc-preset=preset-5] .link-primary:hover, [data-pc-preset=preset-5] .link-primary:focus {
  color: #c3352b !important;
}
[data-pc-preset=preset-5] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #f44236;
  --bs-btn-border-color: #f44236;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #cf382e;
  --bs-btn-hover-border-color: #c3352b;
  --bs-btn-focus-shadow-rgb: 246, 94, 84;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #c3352b;
  --bs-btn-active-border-color: #b73229;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #f44236;
  --bs-btn-disabled-border-color: #f44236;
}
[data-pc-preset=preset-5] .btn-link {
  --bs-btn-color: #f44236;
  --bs-btn-hover-color: #c3352b;
  --bs-btn-active-color: #c3352b;
}
[data-pc-preset=preset-5] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(244, 66, 54, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-5] .accordion {
  --bs-accordion-btn-focus-border-color: #f44236;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(244, 66, 54, 0.25);
  --bs-accordion-active-color: #f44236;
  --bs-accordion-active-bg: #feeceb;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f44236'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .alert-primary {
  --bs-alert-color: #922820;
  --bs-alert-bg: #fdd9d7;
  --bs-alert-border-color: #fcc6c3;
  --bs-alert-link-color: #75201a;
}
[data-pc-preset=preset-5] .list-group {
  --bs-list-group-active-bg: #f44236;
  --bs-list-group-active-border-color: #f44236;
}
[data-pc-preset=preset-5] .list-group-item-primary {
  color: #922820;
  background-color: #fdd9d7;
}
[data-pc-preset=preset-5] .nav {
  --bs-nav-link-hover-color: #c3352b;
}
[data-pc-preset=preset-5] .nav-pills {
  --bs-nav-pills-link-active-bg: #f44236;
}
[data-pc-preset=preset-5] .pagination {
  --bs-pagination-hover-color: #c3352b;
  --bs-pagination-focus-color: #c3352b;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(244, 66, 54, 0.25);
  --bs-pagination-active-bg: #f44236;
  --bs-pagination-active-border-color: #f44236;
}
[data-pc-preset=preset-5] .progress {
  --bs-progress-bar-bg: #f44236;
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-primary:checked {
  border-color: #f44236;
  background-color: #f44236;
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:checked {
  border-color: #feeceb;
  background-color: #feeceb;
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23f44236' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23f44236'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-5] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(244, 66, 54, 0.25);
  border-color: #f44236;
}
[data-pc-preset=preset-5] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f44236'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .btn-light-primary {
  background: #feeceb;
  color: #f44236;
  border-color: #feeceb;
}
[data-pc-preset=preset-5] .btn-light-primary .material-icons-two-tone {
  background-color: #f44236;
}
[data-pc-preset=preset-5] .btn-light-primary:hover {
  background: #f44236;
  color: #fff;
  border-color: #f44236;
}
[data-pc-preset=preset-5] .btn-light-primary.focus, [data-pc-preset=preset-5] .btn-light-primary:focus {
  background: #f44236;
  color: #fff;
  border-color: #f44236;
}
[data-pc-preset=preset-5] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-5] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-5] .btn-light-primary.dropdown-toggle {
  background: #f44236;
  color: #fff;
  border-color: #f44236;
}
[data-pc-preset=preset-5] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-5] .btn-check:checked + .btn-light-primary {
  background: #f44236;
  color: #fff;
  border-color: #f44236;
}
[data-pc-preset=preset-5] .btn-link-primary {
  background: transparent;
  color: #f44236;
  border-color: transparent;
}
[data-pc-preset=preset-5] .btn-link-primary .material-icons-two-tone {
  background-color: #f44236;
}
[data-pc-preset=preset-5] .btn-link-primary:hover {
  background: #feeceb;
  color: #f44236;
  border-color: #feeceb;
}
[data-pc-preset=preset-5] .btn-link-primary.focus, [data-pc-preset=preset-5] .btn-link-primary:focus {
  background: #feeceb;
  color: #f44236;
  border-color: #feeceb;
}
[data-pc-preset=preset-5] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-5] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-5] .btn-link-primary.dropdown-toggle {
  background: #feeceb;
  color: #f44236;
  border-color: #feeceb;
}
[data-pc-preset=preset-5] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-5] .btn-check:checked + .btn-link-primary {
  background: #feeceb;
  color: #f44236;
  border-color: #feeceb;
}
[data-pc-preset=preset-5] .slider-selection {
  background-image: linear-gradient(to bottom, #fbb4af 0, #fbb4af 100%);
}
[data-pc-preset=preset-5] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #f99d97 0, #f99d97 100%);
}
[data-pc-preset=preset-5] .swal-button:not([disabled]):hover {
  background-color: #f32b1e;
}
[data-pc-preset=preset-5] .swal-button:active {
  background-color: #f32b1e;
}
[data-pc-preset=preset-5] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(244, 66, 54, 0.29);
}
[data-pc-preset=preset-5] .swal-content__input:focus {
  border-color: rgba(244, 66, 54, 0.29);
}
[data-pc-preset=preset-5] .swal-content__textarea:focus {
  border-color: rgba(244, 66, 54, 0.29);
}
[data-pc-preset=preset-5] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(244, 66, 54, 0.4) !important;
}
[data-pc-preset=preset-5] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #f99d97 0, #f99d97 100%);
}
[data-pc-preset=preset-5] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #f44236;
  --bs-table-border-color: #f5554a;
  --bs-table-striped-bg: #f54b40;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #f5554a;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #f4463a;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-6] {
  --pc-sidebar-active-color: #fd7e14;
  --bs-blue: #fd7e14;
  --bs-primary: #fd7e14;
  --bs-primary-rgb: 253, 126, 20;
  --bs-primary-light: #fff2e8;
  --bs-link-color: #fd7e14;
  --bs-link-color-rgb: 253, 126, 20;
  --bs-link-hover-color: #ca6510;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 253, 126, 20;
}
[data-pc-preset=preset-6] .bg-light-primary {
  background: #fff2e8;
  color: #fd7e14;
}
[data-pc-preset=preset-6] .link-primary {
  color: #fd7e14 !important;
}
[data-pc-preset=preset-6] .link-primary:hover, [data-pc-preset=preset-6] .link-primary:focus {
  color: #ca6510 !important;
}
[data-pc-preset=preset-6] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #fd7e14;
  --bs-btn-border-color: #fd7e14;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #d76b11;
  --bs-btn-hover-border-color: #ca6510;
  --bs-btn-focus-shadow-rgb: 253, 145, 55;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ca6510;
  --bs-btn-active-border-color: #be5f0f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #fd7e14;
  --bs-btn-disabled-border-color: #fd7e14;
}
[data-pc-preset=preset-6] .btn-link {
  --bs-btn-color: #fd7e14;
  --bs-btn-hover-color: #ca6510;
  --bs-btn-active-color: #ca6510;
}
[data-pc-preset=preset-6] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(253, 126, 20, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-6] .accordion {
  --bs-accordion-btn-focus-border-color: #fd7e14;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.25);
  --bs-accordion-active-color: #fd7e14;
  --bs-accordion-active-bg: #fff2e8;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fd7e14'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .alert-primary {
  --bs-alert-color: #984c0c;
  --bs-alert-bg: #ffe5d0;
  --bs-alert-border-color: #fed8b9;
  --bs-alert-link-color: #7a3d0a;
}
[data-pc-preset=preset-6] .list-group {
  --bs-list-group-active-bg: #fd7e14;
  --bs-list-group-active-border-color: #fd7e14;
}
[data-pc-preset=preset-6] .list-group-item-primary {
  color: #984c0c;
  background-color: #ffe5d0;
}
[data-pc-preset=preset-6] .nav {
  --bs-nav-link-hover-color: #ca6510;
}
[data-pc-preset=preset-6] .nav-pills {
  --bs-nav-pills-link-active-bg: #fd7e14;
}
[data-pc-preset=preset-6] .pagination {
  --bs-pagination-hover-color: #ca6510;
  --bs-pagination-focus-color: #ca6510;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.25);
  --bs-pagination-active-bg: #fd7e14;
  --bs-pagination-active-border-color: #fd7e14;
}
[data-pc-preset=preset-6] .progress {
  --bs-progress-bar-bg: #fd7e14;
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-primary:checked {
  border-color: #fd7e14;
  background-color: #fd7e14;
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:checked {
  border-color: #fff2e8;
  background-color: #fff2e8;
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fd7e14' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fd7e14'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-6] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.25);
  border-color: #fd7e14;
}
[data-pc-preset=preset-6] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fd7e14'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .btn-light-primary {
  background: #fff2e8;
  color: #fd7e14;
  border-color: #fff2e8;
}
[data-pc-preset=preset-6] .btn-light-primary .material-icons-two-tone {
  background-color: #fd7e14;
}
[data-pc-preset=preset-6] .btn-light-primary:hover {
  background: #fd7e14;
  color: #fff;
  border-color: #fd7e14;
}
[data-pc-preset=preset-6] .btn-light-primary.focus, [data-pc-preset=preset-6] .btn-light-primary:focus {
  background: #fd7e14;
  color: #fff;
  border-color: #fd7e14;
}
[data-pc-preset=preset-6] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-6] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-6] .btn-light-primary.dropdown-toggle {
  background: #fd7e14;
  color: #fff;
  border-color: #fd7e14;
}
[data-pc-preset=preset-6] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-6] .btn-check:checked + .btn-light-primary {
  background: #fd7e14;
  color: #fff;
  border-color: #fd7e14;
}
[data-pc-preset=preset-6] .btn-link-primary {
  background: transparent;
  color: #fd7e14;
  border-color: transparent;
}
[data-pc-preset=preset-6] .btn-link-primary .material-icons-two-tone {
  background-color: #fd7e14;
}
[data-pc-preset=preset-6] .btn-link-primary:hover {
  background: #fff2e8;
  color: #fd7e14;
  border-color: #fff2e8;
}
[data-pc-preset=preset-6] .btn-link-primary.focus, [data-pc-preset=preset-6] .btn-link-primary:focus {
  background: #fff2e8;
  color: #fd7e14;
  border-color: #fff2e8;
}
[data-pc-preset=preset-6] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-6] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-6] .btn-link-primary.dropdown-toggle {
  background: #fff2e8;
  color: #fd7e14;
  border-color: #fff2e8;
}
[data-pc-preset=preset-6] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-6] .btn-check:checked + .btn-link-primary {
  background: #fff2e8;
  color: #fd7e14;
  border-color: #fff2e8;
}
[data-pc-preset=preset-6] .slider-selection {
  background-image: linear-gradient(to bottom, #fec392 0, #fec392 100%);
}
[data-pc-preset=preset-6] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #feb679 0, #feb679 100%);
}
[data-pc-preset=preset-6] .swal-button:not([disabled]):hover {
  background-color: #f57102;
}
[data-pc-preset=preset-6] .swal-button:active {
  background-color: #f57102;
}
[data-pc-preset=preset-6] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(253, 126, 20, 0.29);
}
[data-pc-preset=preset-6] .swal-content__input:focus {
  border-color: rgba(253, 126, 20, 0.29);
}
[data-pc-preset=preset-6] .swal-content__textarea:focus {
  border-color: rgba(253, 126, 20, 0.29);
}
[data-pc-preset=preset-6] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(253, 126, 20, 0.4) !important;
}
[data-pc-preset=preset-6] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #feb679 0, #feb679 100%);
}
[data-pc-preset=preset-6] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #fd7e14;
  --bs-table-border-color: #fd8b2c;
  --bs-table-striped-bg: #fd8420;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #fd8b2c;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #fd8119;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-7] {
  --pc-sidebar-active-color: #f4c22b;
  --bs-blue: #f4c22b;
  --bs-primary: #f4c22b;
  --bs-primary-rgb: 244, 194, 43;
  --bs-primary-light: #fef9ea;
  --bs-link-color: #f4c22b;
  --bs-link-color-rgb: 244, 194, 43;
  --bs-link-hover-color: #c39b22;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 244, 194, 43;
}
[data-pc-preset=preset-7] .bg-light-primary {
  background: #fef9ea;
  color: #f4c22b;
}
[data-pc-preset=preset-7] .link-primary {
  color: #f4c22b !important;
}
[data-pc-preset=preset-7] .link-primary:hover, [data-pc-preset=preset-7] .link-primary:focus {
  color: #c39b22 !important;
}
[data-pc-preset=preset-7] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #f4c22b;
  --bs-btn-border-color: #f4c22b;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #cfa525;
  --bs-btn-hover-border-color: #c39b22;
  --bs-btn-focus-shadow-rgb: 246, 203, 75;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #c39b22;
  --bs-btn-active-border-color: #b79220;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #f4c22b;
  --bs-btn-disabled-border-color: #f4c22b;
}
[data-pc-preset=preset-7] .btn-link {
  --bs-btn-color: #f4c22b;
  --bs-btn-hover-color: #c39b22;
  --bs-btn-active-color: #c39b22;
}
[data-pc-preset=preset-7] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(244, 194, 43, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-7] .accordion {
  --bs-accordion-btn-focus-border-color: #f4c22b;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(244, 194, 43, 0.25);
  --bs-accordion-active-color: #f4c22b;
  --bs-accordion-active-bg: #fef9ea;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f4c22b'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .alert-primary {
  --bs-alert-color: #92741a;
  --bs-alert-bg: #fdf3d5;
  --bs-alert-border-color: #fcedbf;
  --bs-alert-link-color: #755d15;
}
[data-pc-preset=preset-7] .list-group {
  --bs-list-group-active-bg: #f4c22b;
  --bs-list-group-active-border-color: #f4c22b;
}
[data-pc-preset=preset-7] .list-group-item-primary {
  color: #92741a;
  background-color: #fdf3d5;
}
[data-pc-preset=preset-7] .nav {
  --bs-nav-link-hover-color: #c39b22;
}
[data-pc-preset=preset-7] .nav-pills {
  --bs-nav-pills-link-active-bg: #f4c22b;
}
[data-pc-preset=preset-7] .pagination {
  --bs-pagination-hover-color: #c39b22;
  --bs-pagination-focus-color: #c39b22;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(244, 194, 43, 0.25);
  --bs-pagination-active-bg: #f4c22b;
  --bs-pagination-active-border-color: #f4c22b;
}
[data-pc-preset=preset-7] .progress {
  --bs-progress-bar-bg: #f4c22b;
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-primary:checked {
  border-color: #f4c22b;
  background-color: #f4c22b;
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:checked {
  border-color: #fef9ea;
  background-color: #fef9ea;
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23f4c22b' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23f4c22b'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-7] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(244, 194, 43, 0.25);
  border-color: #f4c22b;
}
[data-pc-preset=preset-7] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f4c22b'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .btn-light-primary {
  background: #fef9ea;
  color: #f4c22b;
  border-color: #fef9ea;
}
[data-pc-preset=preset-7] .btn-light-primary .material-icons-two-tone {
  background-color: #f4c22b;
}
[data-pc-preset=preset-7] .btn-light-primary:hover {
  background: #f4c22b;
  color: #fff;
  border-color: #f4c22b;
}
[data-pc-preset=preset-7] .btn-light-primary.focus, [data-pc-preset=preset-7] .btn-light-primary:focus {
  background: #f4c22b;
  color: #fff;
  border-color: #f4c22b;
}
[data-pc-preset=preset-7] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-7] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-7] .btn-light-primary.dropdown-toggle {
  background: #f4c22b;
  color: #fff;
  border-color: #f4c22b;
}
[data-pc-preset=preset-7] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-7] .btn-check:checked + .btn-light-primary {
  background: #f4c22b;
  color: #fff;
  border-color: #f4c22b;
}
[data-pc-preset=preset-7] .btn-link-primary {
  background: transparent;
  color: #f4c22b;
  border-color: transparent;
}
[data-pc-preset=preset-7] .btn-link-primary .material-icons-two-tone {
  background-color: #f4c22b;
}
[data-pc-preset=preset-7] .btn-link-primary:hover {
  background: #fef9ea;
  color: #f4c22b;
  border-color: #fef9ea;
}
[data-pc-preset=preset-7] .btn-link-primary.focus, [data-pc-preset=preset-7] .btn-link-primary:focus {
  background: #fef9ea;
  color: #f4c22b;
  border-color: #fef9ea;
}
[data-pc-preset=preset-7] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-7] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-7] .btn-link-primary.dropdown-toggle {
  background: #fef9ea;
  color: #f4c22b;
  border-color: #fef9ea;
}
[data-pc-preset=preset-7] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-7] .btn-check:checked + .btn-link-primary {
  background: #fef9ea;
  color: #f4c22b;
  border-color: #fef9ea;
}
[data-pc-preset=preset-7] .slider-selection {
  background-image: linear-gradient(to bottom, #fae5a4 0, #fae5a4 100%);
}
[data-pc-preset=preset-7] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #f9de8c 0, #f9de8c 100%);
}
[data-pc-preset=preset-7] .swal-button:not([disabled]):hover {
  background-color: #f3bb13;
}
[data-pc-preset=preset-7] .swal-button:active {
  background-color: #f3bb13;
}
[data-pc-preset=preset-7] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(244, 194, 43, 0.29);
}
[data-pc-preset=preset-7] .swal-content__input:focus {
  border-color: rgba(244, 194, 43, 0.29);
}
[data-pc-preset=preset-7] .swal-content__textarea:focus {
  border-color: rgba(244, 194, 43, 0.29);
}
[data-pc-preset=preset-7] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(244, 194, 43, 0.4) !important;
}
[data-pc-preset=preset-7] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #f9de8c 0, #f9de8c 100%);
}
[data-pc-preset=preset-7] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #f4c22b;
  --bs-table-border-color: #f5c840;
  --bs-table-striped-bg: #f5c536;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #f5c840;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #f4c32f;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-8] {
  --pc-sidebar-active-color: #1de9b6;
  --bs-blue: #1de9b6;
  --bs-primary: #1de9b6;
  --bs-primary-rgb: 29, 233, 182;
  --bs-primary-light: #e8fdf8;
  --bs-link-color: #1de9b6;
  --bs-link-color-rgb: 29, 233, 182;
  --bs-link-hover-color: #17ba92;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 29, 233, 182;
}
[data-pc-preset=preset-8] .bg-light-primary {
  background: #e8fdf8;
  color: #1de9b6;
}
[data-pc-preset=preset-8] .link-primary {
  color: #1de9b6 !important;
}
[data-pc-preset=preset-8] .link-primary:hover, [data-pc-preset=preset-8] .link-primary:focus {
  color: #17ba92 !important;
}
[data-pc-preset=preset-8] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #1de9b6;
  --bs-btn-border-color: #1de9b6;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #19c69b;
  --bs-btn-hover-border-color: #17ba92;
  --bs-btn-focus-shadow-rgb: 63, 236, 193;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #17ba92;
  --bs-btn-active-border-color: #16af89;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #1de9b6;
  --bs-btn-disabled-border-color: #1de9b6;
}
[data-pc-preset=preset-8] .btn-link {
  --bs-btn-color: #1de9b6;
  --bs-btn-hover-color: #17ba92;
  --bs-btn-active-color: #17ba92;
}
[data-pc-preset=preset-8] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(29, 233, 182, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-8] .accordion {
  --bs-accordion-btn-focus-border-color: #1de9b6;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(29, 233, 182, 0.25);
  --bs-accordion-active-color: #1de9b6;
  --bs-accordion-active-bg: #e8fdf8;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231de9b6'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-8] .alert-primary {
  --bs-alert-color: #118c6d;
  --bs-alert-bg: #d2fbf0;
  --bs-alert-border-color: #bbf8e9;
  --bs-alert-link-color: #0e7057;
}
[data-pc-preset=preset-8] .list-group {
  --bs-list-group-active-bg: #1de9b6;
  --bs-list-group-active-border-color: #1de9b6;
}
[data-pc-preset=preset-8] .list-group-item-primary {
  color: #118c6d;
  background-color: #d2fbf0;
}
[data-pc-preset=preset-8] .nav {
  --bs-nav-link-hover-color: #17ba92;
}
[data-pc-preset=preset-8] .nav-pills {
  --bs-nav-pills-link-active-bg: #1de9b6;
}
[data-pc-preset=preset-8] .pagination {
  --bs-pagination-hover-color: #17ba92;
  --bs-pagination-focus-color: #17ba92;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(29, 233, 182, 0.25);
  --bs-pagination-active-bg: #1de9b6;
  --bs-pagination-active-border-color: #1de9b6;
}
[data-pc-preset=preset-8] .progress {
  --bs-progress-bar-bg: #1de9b6;
}
[data-pc-preset=preset-8] .form-check .form-check-input.input-primary:checked {
  border-color: #1de9b6;
  background-color: #1de9b6;
}
[data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:checked {
  border-color: #e8fdf8;
  background-color: #e8fdf8;
}
[data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%231de9b6' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%231de9b6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-8] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-8] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(29, 233, 182, 0.25);
  border-color: #1de9b6;
}
[data-pc-preset=preset-8] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%231de9b6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-8] .btn-light-primary {
  background: #e8fdf8;
  color: #1de9b6;
  border-color: #e8fdf8;
}
[data-pc-preset=preset-8] .btn-light-primary .material-icons-two-tone {
  background-color: #1de9b6;
}
[data-pc-preset=preset-8] .btn-light-primary:hover {
  background: #1de9b6;
  color: #fff;
  border-color: #1de9b6;
}
[data-pc-preset=preset-8] .btn-light-primary.focus, [data-pc-preset=preset-8] .btn-light-primary:focus {
  background: #1de9b6;
  color: #fff;
  border-color: #1de9b6;
}
[data-pc-preset=preset-8] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-8] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-8] .btn-light-primary.dropdown-toggle {
  background: #1de9b6;
  color: #fff;
  border-color: #1de9b6;
}
[data-pc-preset=preset-8] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-8] .btn-check:checked + .btn-light-primary {
  background: #1de9b6;
  color: #fff;
  border-color: #1de9b6;
}
[data-pc-preset=preset-8] .btn-link-primary {
  background: transparent;
  color: #1de9b6;
  border-color: transparent;
}
[data-pc-preset=preset-8] .btn-link-primary .material-icons-two-tone {
  background-color: #1de9b6;
}
[data-pc-preset=preset-8] .btn-link-primary:hover {
  background: #e8fdf8;
  color: #1de9b6;
  border-color: #e8fdf8;
}
[data-pc-preset=preset-8] .btn-link-primary.focus, [data-pc-preset=preset-8] .btn-link-primary:focus {
  background: #e8fdf8;
  color: #1de9b6;
  border-color: #e8fdf8;
}
[data-pc-preset=preset-8] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-8] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-8] .btn-link-primary.dropdown-toggle {
  background: #e8fdf8;
  color: #1de9b6;
  border-color: #e8fdf8;
}
[data-pc-preset=preset-8] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-8] .btn-check:checked + .btn-link-primary {
  background: #e8fdf8;
  color: #1de9b6;
  border-color: #e8fdf8;
}
[data-pc-preset=preset-8] .slider-selection {
  background-image: linear-gradient(to bottom, #91f4dc 0, #91f4dc 100%);
}
[data-pc-preset=preset-8] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #7af2d4 0, #7af2d4 100%);
}
[data-pc-preset=preset-8] .swal-button:not([disabled]):hover {
  background-color: #15d8a7;
}
[data-pc-preset=preset-8] .swal-button:active {
  background-color: #15d8a7;
}
[data-pc-preset=preset-8] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(29, 233, 182, 0.29);
}
[data-pc-preset=preset-8] .swal-content__input:focus {
  border-color: rgba(29, 233, 182, 0.29);
}
[data-pc-preset=preset-8] .swal-content__textarea:focus {
  border-color: rgba(29, 233, 182, 0.29);
}
[data-pc-preset=preset-8] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(29, 233, 182, 0.4) !important;
}
[data-pc-preset=preset-8] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #7af2d4 0, #7af2d4 100%);
}
[data-pc-preset=preset-8] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #1de9b6;
  --bs-table-border-color: #34ebbd;
  --bs-table-striped-bg: #28eaba;
  --bs-table-striped-color: #131920;
  --bs-table-active-bg: #34ebbd;
  --bs-table-active-color: #131920;
  --bs-table-hover-bg: #22e9b7;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-9] {
  --pc-sidebar-active-color: #008080;
  --bs-blue: #008080;
  --bs-primary: #008080;
  --bs-primary-rgb: 0, 128, 128;
  --bs-primary-light: #e6f2f2;
  --bs-link-color: #008080;
  --bs-link-color-rgb: 0, 128, 128;
  --bs-link-hover-color: #006666;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 0, 128, 128;
}
[data-pc-preset=preset-9] .bg-light-primary {
  background: #e6f2f2;
  color: #008080;
}
[data-pc-preset=preset-9] .link-primary {
  color: #008080 !important;
}
[data-pc-preset=preset-9] .link-primary:hover, [data-pc-preset=preset-9] .link-primary:focus {
  color: #006666 !important;
}
[data-pc-preset=preset-9] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #008080;
  --bs-btn-border-color: #008080;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #006d6d;
  --bs-btn-hover-border-color: #006666;
  --bs-btn-focus-shadow-rgb: 38, 147, 147;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #006666;
  --bs-btn-active-border-color: #006060;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #008080;
  --bs-btn-disabled-border-color: #008080;
}
[data-pc-preset=preset-9] .btn-link {
  --bs-btn-color: #008080;
  --bs-btn-hover-color: #006666;
  --bs-btn-active-color: #006666;
}
[data-pc-preset=preset-9] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(0, 128, 128, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-9] .accordion {
  --bs-accordion-btn-focus-border-color: #008080;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(0, 128, 128, 0.25);
  --bs-accordion-active-color: #008080;
  --bs-accordion-active-bg: #e6f2f2;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23008080'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-9] .alert-primary {
  --bs-alert-color: #004d4d;
  --bs-alert-bg: #cce6e6;
  --bs-alert-border-color: #b3d9d9;
  --bs-alert-link-color: #003e3e;
}
[data-pc-preset=preset-9] .list-group {
  --bs-list-group-active-bg: #008080;
  --bs-list-group-active-border-color: #008080;
}
[data-pc-preset=preset-9] .list-group-item-primary {
  color: #004d4d;
  background-color: #cce6e6;
}
[data-pc-preset=preset-9] .nav {
  --bs-nav-link-hover-color: #006666;
}
[data-pc-preset=preset-9] .nav-pills {
  --bs-nav-pills-link-active-bg: #008080;
}
[data-pc-preset=preset-9] .pagination {
  --bs-pagination-hover-color: #006666;
  --bs-pagination-focus-color: #006666;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(0, 128, 128, 0.25);
  --bs-pagination-active-bg: #008080;
  --bs-pagination-active-border-color: #008080;
}
[data-pc-preset=preset-9] .progress {
  --bs-progress-bar-bg: #008080;
}
[data-pc-preset=preset-9] .form-check .form-check-input.input-primary:checked {
  border-color: #008080;
  background-color: #008080;
}
[data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:checked {
  border-color: #e6f2f2;
  background-color: #e6f2f2;
}
[data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23008080' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23008080'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-9] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-9] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(0, 128, 128, 0.25);
  border-color: #008080;
}
[data-pc-preset=preset-9] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23008080'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-9] .btn-light-primary {
  background: #e6f2f2;
  color: #008080;
  border-color: #e6f2f2;
}
[data-pc-preset=preset-9] .btn-light-primary .material-icons-two-tone {
  background-color: #008080;
}
[data-pc-preset=preset-9] .btn-light-primary:hover {
  background: #008080;
  color: #fff;
  border-color: #008080;
}
[data-pc-preset=preset-9] .btn-light-primary.focus, [data-pc-preset=preset-9] .btn-light-primary:focus {
  background: #008080;
  color: #fff;
  border-color: #008080;
}
[data-pc-preset=preset-9] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-9] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-9] .btn-light-primary.dropdown-toggle {
  background: #008080;
  color: #fff;
  border-color: #008080;
}
[data-pc-preset=preset-9] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-9] .btn-check:checked + .btn-light-primary {
  background: #008080;
  color: #fff;
  border-color: #008080;
}
[data-pc-preset=preset-9] .btn-link-primary {
  background: transparent;
  color: #008080;
  border-color: transparent;
}
[data-pc-preset=preset-9] .btn-link-primary .material-icons-two-tone {
  background-color: #008080;
}
[data-pc-preset=preset-9] .btn-link-primary:hover {
  background: #e6f2f2;
  color: #008080;
  border-color: #e6f2f2;
}
[data-pc-preset=preset-9] .btn-link-primary.focus, [data-pc-preset=preset-9] .btn-link-primary:focus {
  background: #e6f2f2;
  color: #008080;
  border-color: #e6f2f2;
}
[data-pc-preset=preset-9] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-9] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-9] .btn-link-primary.dropdown-toggle {
  background: #e6f2f2;
  color: #008080;
  border-color: #e6f2f2;
}
[data-pc-preset=preset-9] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-9] .btn-check:checked + .btn-link-primary {
  background: #e6f2f2;
  color: #008080;
  border-color: #e6f2f2;
}
[data-pc-preset=preset-9] .slider-selection {
  background-image: linear-gradient(to bottom, #01ffff 0, #01ffff 100%);
}
[data-pc-preset=preset-9] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #00e6e6 0, #00e6e6 100%);
}
[data-pc-preset=preset-9] .swal-button:not([disabled]):hover {
  background-color: #006767;
}
[data-pc-preset=preset-9] .swal-button:active {
  background-color: #006767;
}
[data-pc-preset=preset-9] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(0, 128, 128, 0.29);
}
[data-pc-preset=preset-9] .swal-content__input:focus {
  border-color: rgba(0, 128, 128, 0.29);
}
[data-pc-preset=preset-9] .swal-content__textarea:focus {
  border-color: rgba(0, 128, 128, 0.29);
}
[data-pc-preset=preset-9] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(0, 128, 128, 0.4) !important;
}
[data-pc-preset=preset-9] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #00e6e6 0, #00e6e6 100%);
}
[data-pc-preset=preset-9] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #008080;
  --bs-table-border-color: #1a8d8d;
  --bs-table-striped-bg: #0d8686;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #1a8d8d;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #058383;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-10] {
  --pc-sidebar-active-color: #3ebfea;
  --bs-blue: #3ebfea;
  --bs-primary: #3ebfea;
  --bs-primary-rgb: 62, 191, 234;
  --bs-primary-light: #ecf9fd;
  --bs-link-color: #3ebfea;
  --bs-link-color-rgb: 62, 191, 234;
  --bs-link-hover-color: #3299bb;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 62, 191, 234;
}
[data-pc-preset=preset-10] .bg-light-primary {
  background: #ecf9fd;
  color: #3ebfea;
}
[data-pc-preset=preset-10] .link-primary {
  color: #3ebfea !important;
}
[data-pc-preset=preset-10] .link-primary:hover, [data-pc-preset=preset-10] .link-primary:focus {
  color: #3299bb !important;
}
[data-pc-preset=preset-10] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #3ebfea;
  --bs-btn-border-color: #3ebfea;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #35a2c7;
  --bs-btn-hover-border-color: #3299bb;
  --bs-btn-focus-shadow-rgb: 91, 201, 237;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #3299bb;
  --bs-btn-active-border-color: #2f8fb0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #3ebfea;
  --bs-btn-disabled-border-color: #3ebfea;
}
[data-pc-preset=preset-10] .btn-link {
  --bs-btn-color: #3ebfea;
  --bs-btn-hover-color: #3299bb;
  --bs-btn-active-color: #3299bb;
}
[data-pc-preset=preset-10] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(62, 191, 234, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-10] .accordion {
  --bs-accordion-btn-focus-border-color: #3ebfea;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(62, 191, 234, 0.25);
  --bs-accordion-active-color: #3ebfea;
  --bs-accordion-active-bg: #ecf9fd;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233ebfea'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-10] .alert-primary {
  --bs-alert-color: #25738c;
  --bs-alert-bg: #d8f2fb;
  --bs-alert-border-color: #c5ecf9;
  --bs-alert-link-color: #1e5c70;
}
[data-pc-preset=preset-10] .list-group {
  --bs-list-group-active-bg: #3ebfea;
  --bs-list-group-active-border-color: #3ebfea;
}
[data-pc-preset=preset-10] .list-group-item-primary {
  color: #25738c;
  background-color: #d8f2fb;
}
[data-pc-preset=preset-10] .nav {
  --bs-nav-link-hover-color: #3299bb;
}
[data-pc-preset=preset-10] .nav-pills {
  --bs-nav-pills-link-active-bg: #3ebfea;
}
[data-pc-preset=preset-10] .pagination {
  --bs-pagination-hover-color: #3299bb;
  --bs-pagination-focus-color: #3299bb;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(62, 191, 234, 0.25);
  --bs-pagination-active-bg: #3ebfea;
  --bs-pagination-active-border-color: #3ebfea;
}
[data-pc-preset=preset-10] .progress {
  --bs-progress-bar-bg: #3ebfea;
}
[data-pc-preset=preset-10] .form-check .form-check-input.input-primary:checked {
  border-color: #3ebfea;
  background-color: #3ebfea;
}
[data-pc-preset=preset-10] .form-check .form-check-input.input-light-primary:checked {
  border-color: #ecf9fd;
  background-color: #ecf9fd;
}
[data-pc-preset=preset-10] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%233ebfea' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-10] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%233ebfea'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-10] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-10] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-10] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-10] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(62, 191, 234, 0.25);
  border-color: #3ebfea;
}
[data-pc-preset=preset-10] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%233ebfea'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-10] .btn-light-primary {
  background: #ecf9fd;
  color: #3ebfea;
  border-color: #ecf9fd;
}
[data-pc-preset=preset-10] .btn-light-primary .material-icons-two-tone {
  background-color: #3ebfea;
}
[data-pc-preset=preset-10] .btn-light-primary:hover {
  background: #3ebfea;
  color: #fff;
  border-color: #3ebfea;
}
[data-pc-preset=preset-10] .btn-light-primary.focus, [data-pc-preset=preset-10] .btn-light-primary:focus {
  background: #3ebfea;
  color: #fff;
  border-color: #3ebfea;
}
[data-pc-preset=preset-10] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-10] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-10] .btn-light-primary.dropdown-toggle {
  background: #3ebfea;
  color: #fff;
  border-color: #3ebfea;
}
[data-pc-preset=preset-10] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-10] .btn-check:checked + .btn-light-primary {
  background: #3ebfea;
  color: #fff;
  border-color: #3ebfea;
}
[data-pc-preset=preset-10] .btn-link-primary {
  background: transparent;
  color: #3ebfea;
  border-color: transparent;
}
[data-pc-preset=preset-10] .btn-link-primary .material-icons-two-tone {
  background-color: #3ebfea;
}
[data-pc-preset=preset-10] .btn-link-primary:hover {
  background: #ecf9fd;
  color: #3ebfea;
  border-color: #ecf9fd;
}
[data-pc-preset=preset-10] .btn-link-primary.focus, [data-pc-preset=preset-10] .btn-link-primary:focus {
  background: #ecf9fd;
  color: #3ebfea;
  border-color: #ecf9fd;
}
[data-pc-preset=preset-10] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-10] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-10] .btn-link-primary.dropdown-toggle {
  background: #ecf9fd;
  color: #3ebfea;
  border-color: #ecf9fd;
}
[data-pc-preset=preset-10] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-10] .btn-check:checked + .btn-link-primary {
  background: #ecf9fd;
  color: #3ebfea;
  border-color: #ecf9fd;
}
[data-pc-preset=preset-10] .slider-selection {
  background-image: linear-gradient(to bottom, #b1e5f7 0, #b1e5f7 100%);
}
[data-pc-preset=preset-10] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #9adef4 0, #9adef4 100%);
}
[data-pc-preset=preset-10] .swal-button:not([disabled]):hover {
  background-color: #27b7e7;
}
[data-pc-preset=preset-10] .swal-button:active {
  background-color: #27b7e7;
}
[data-pc-preset=preset-10] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(62, 191, 234, 0.29);
}
[data-pc-preset=preset-10] .swal-content__input:focus {
  border-color: rgba(62, 191, 234, 0.29);
}
[data-pc-preset=preset-10] .swal-content__textarea:focus {
  border-color: rgba(62, 191, 234, 0.29);
}
[data-pc-preset=preset-10] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(62, 191, 234, 0.4) !important;
}
[data-pc-preset=preset-10] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #9adef4 0, #9adef4 100%);
}
[data-pc-preset=preset-10] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #3ebfea;
  --bs-table-border-color: #51c5ec;
  --bs-table-striped-bg: #48c2eb;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #51c5ec;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #42c0ea;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-preset=preset-11] {
  --pc-sidebar-active-color: #131920;
  --bs-blue: #131920;
  --bs-primary: #131920;
  --bs-primary-rgb: 19, 25, 32;
  --bs-primary-light: #e7e8e9;
  --bs-link-color: #131920;
  --bs-link-color-rgb: 19, 25, 32;
  --bs-link-hover-color: #0f141a;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
  --dt-row-selected: 19, 25, 32;
}
[data-pc-preset=preset-11] .bg-light-primary {
  background: #e7e8e9;
  color: #131920;
}
[data-pc-preset=preset-11] .link-primary {
  color: #131920 !important;
}
[data-pc-preset=preset-11] .link-primary:hover, [data-pc-preset=preset-11] .link-primary:focus {
  color: #0f141a !important;
}
[data-pc-preset=preset-11] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #131920;
  --bs-btn-border-color: #131920;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #10151b;
  --bs-btn-hover-border-color: #0f141a;
  --bs-btn-focus-shadow-rgb: 54, 60, 65;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #0f141a;
  --bs-btn-active-border-color: #0e1318;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #131920;
  --bs-btn-disabled-border-color: #131920;
}
[data-pc-preset=preset-11] .btn-link {
  --bs-btn-color: #131920;
  --bs-btn-hover-color: #0f141a;
  --bs-btn-active-color: #0f141a;
}
[data-pc-preset=preset-11] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(19, 25, 32, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-11] .accordion {
  --bs-accordion-btn-focus-border-color: #131920;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(19, 25, 32, 0.25);
  --bs-accordion-active-color: #131920;
  --bs-accordion-active-bg: #e7e8e9;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23131920'%3e%3cpath fill-rule=' evenodd' d=' M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-11] .alert-primary {
  --bs-alert-color: #0b0f13;
  --bs-alert-bg: #d0d1d2;
  --bs-alert-border-color: #b8babc;
  --bs-alert-link-color: #090c0f;
}
[data-pc-preset=preset-11] .list-group {
  --bs-list-group-active-bg: #131920;
  --bs-list-group-active-border-color: #131920;
}
[data-pc-preset=preset-11] .list-group-item-primary {
  color: #0b0f13;
  background-color: #d0d1d2;
}
[data-pc-preset=preset-11] .nav {
  --bs-nav-link-hover-color: #0f141a;
}
[data-pc-preset=preset-11] .nav-pills {
  --bs-nav-pills-link-active-bg: #131920;
}
[data-pc-preset=preset-11] .pagination {
  --bs-pagination-hover-color: #0f141a;
  --bs-pagination-focus-color: #0f141a;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(19, 25, 32, 0.25);
  --bs-pagination-active-bg: #131920;
  --bs-pagination-active-border-color: #131920;
}
[data-pc-preset=preset-11] .progress {
  --bs-progress-bar-bg: #131920;
}
[data-pc-preset=preset-11] .form-check .form-check-input.input-primary:checked {
  border-color: #131920;
  background-color: #131920;
}
[data-pc-preset=preset-11] .form-check .form-check-input.input-light-primary:checked {
  border-color: #e7e8e9;
  background-color: #e7e8e9;
}
[data-pc-preset=preset-11] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23131920' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-11] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23131920'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-11] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-11] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-11] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-11] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(19, 25, 32, 0.25);
  border-color: #131920;
}
[data-pc-preset=preset-11] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23131920'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-11] .btn-light-primary {
  background: #e7e8e9;
  color: #131920;
  border-color: #e7e8e9;
}
[data-pc-preset=preset-11] .btn-light-primary .material-icons-two-tone {
  background-color: #131920;
}
[data-pc-preset=preset-11] .btn-light-primary:hover {
  background: #131920;
  color: #fff;
  border-color: #131920;
}
[data-pc-preset=preset-11] .btn-light-primary.focus, [data-pc-preset=preset-11] .btn-light-primary:focus {
  background: #131920;
  color: #fff;
  border-color: #131920;
}
[data-pc-preset=preset-11] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-11] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-11] .btn-light-primary.dropdown-toggle {
  background: #131920;
  color: #fff;
  border-color: #131920;
}
[data-pc-preset=preset-11] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-11] .btn-check:checked + .btn-light-primary {
  background: #131920;
  color: #fff;
  border-color: #131920;
}
[data-pc-preset=preset-11] .btn-link-primary {
  background: transparent;
  color: #131920;
  border-color: transparent;
}
[data-pc-preset=preset-11] .btn-link-primary .material-icons-two-tone {
  background-color: #131920;
}
[data-pc-preset=preset-11] .btn-link-primary:hover {
  background: #e7e8e9;
  color: #131920;
  border-color: #e7e8e9;
}
[data-pc-preset=preset-11] .btn-link-primary.focus, [data-pc-preset=preset-11] .btn-link-primary:focus {
  background: #e7e8e9;
  color: #131920;
  border-color: #e7e8e9;
}
[data-pc-preset=preset-11] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-11] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-11] .btn-link-primary.dropdown-toggle {
  background: #e7e8e9;
  color: #131920;
  border-color: #e7e8e9;
}
[data-pc-preset=preset-11] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-11] .btn-check:checked + .btn-link-primary {
  background: #e7e8e9;
  color: #131920;
  border-color: #e7e8e9;
}
[data-pc-preset=preset-11] .slider-selection {
  background-image: linear-gradient(to bottom, #435870 0, #435870 100%);
}
[data-pc-preset=preset-11] .slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #394b60 0, #394b60 100%);
}
[data-pc-preset=preset-11] .swal-button:not([disabled]):hover {
  background-color: #0a0d10;
}
[data-pc-preset=preset-11] .swal-button:active {
  background-color: #0a0d10;
}
[data-pc-preset=preset-11] .swal-button:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(19, 25, 32, 0.29);
}
[data-pc-preset=preset-11] .swal-content__input:focus {
  border-color: rgba(19, 25, 32, 0.29);
}
[data-pc-preset=preset-11] .swal-content__textarea:focus {
  border-color: rgba(19, 25, 32, 0.29);
}
[data-pc-preset=preset-11] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(19, 25, 32, 0.4) !important;
}
[data-pc-preset=preset-11] .slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #394b60 0, #394b60 100%);
}
[data-pc-preset=preset-11] .table-primary {
  --bs-table-color: #ffffff;
  --bs-table-bg: #131920;
  --bs-table-border-color: #2b3036;
  --bs-table-striped-bg: #1f252b;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #2b3036;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #181e24;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

[data-pc-direction=rtl] {
  direction: rtl;
  text-align: right;
}
[data-pc-direction=rtl] .p-l-0 {
  padding-right: 0px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-l-0[class*=col] {
  padding-left: 15px;
}
[data-pc-direction=rtl] .p-r-0 {
  padding-left: 0px;
  padding-right: 0;
}
[data-pc-direction=rtl] .p-r-0[class*=col] {
  padding-right: 15px;
}
[data-pc-direction=rtl] .m-l-0 {
  margin-right: 0px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-0 {
  margin-left: 0px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-5 {
  padding-right: 5px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-5 {
  padding-left: 5px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-5 {
  margin-right: 5px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-5 {
  margin-left: 5px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-10 {
  padding-right: 10px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-10 {
  padding-left: 10px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-10 {
  margin-right: 10px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-10 {
  margin-left: 10px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-15 {
  padding-right: 15px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-15 {
  padding-left: 15px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-15 {
  margin-right: 15px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-15 {
  margin-left: 15px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-20 {
  padding-right: 20px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-20 {
  padding-left: 20px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-20 {
  margin-right: 20px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-20 {
  margin-left: 20px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-25 {
  padding-right: 25px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-25 {
  padding-left: 25px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-25 {
  margin-right: 25px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-25 {
  margin-left: 25px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-30 {
  padding-right: 30px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-30 {
  padding-left: 30px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-30 {
  margin-right: 30px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-30 {
  margin-left: 30px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-35 {
  padding-right: 35px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-35 {
  padding-left: 35px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-35 {
  margin-right: 35px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-35 {
  margin-left: 35px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-40 {
  padding-right: 40px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-40 {
  padding-left: 40px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-40 {
  margin-right: 40px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-40 {
  margin-left: 40px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-45 {
  padding-right: 45px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-45 {
  padding-left: 45px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-45 {
  margin-right: 45px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-45 {
  margin-left: 45px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-50 {
  padding-right: 50px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-50 {
  padding-left: 50px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-50 {
  margin-right: 50px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-50 {
  margin-left: 50px;
  margin-right: 0;
}
[data-pc-direction=rtl] ol:not([class]),
[data-pc-direction=rtl] ul:not([class]) {
  padding-left: 0;
  padding-right: 2rem;
}
[data-pc-direction=rtl] dl,
[data-pc-direction=rtl] .list-inline,
[data-pc-direction=rtl] .list-group,
[data-pc-direction=rtl] .list-unstyled {
  padding-right: 0;
  padding-left: 0;
}
[data-pc-direction=rtl] dd {
  margin-right: 0;
}
[data-pc-direction=rtl] .ph-duotone {
  direction: ltr;
}
[data-pc-direction=rtl] .navbar-nav {
  padding-right: 0;
}
[data-pc-direction=rtl] .breadcrumb-item + .breadcrumb-item {
  padding-right: var(--bs-breadcrumb-item-padding-x);
  padding-left: unset;
}
[data-pc-direction=rtl] .breadcrumb-item + .breadcrumb-item::before {
  float: right;
  padding-left: var(--bs-breadcrumb-item-padding-x);
  padding-right: unset;
}
[data-pc-direction=rtl] .apexcharts-canvas {
  direction: ltr;
  text-align: left;
}
[data-pc-direction=rtl] .dropdown-menu {
  text-align: right;
}
[data-pc-direction=rtl] .dropdown .dropdown-item i {
  margin-right: 0;
  margin-left: 10px;
}
[data-pc-direction=rtl] .alert-dismissible {
  padding-right: 1.25rem;
  padding-left: 3.75rem;
}
[data-pc-direction=rtl] .alert-dismissible .btn-close {
  right: auto;
  left: 0;
}
[data-pc-direction=rtl] .accordion-button::after {
  margin-right: auto;
  margin-left: 0;
}
[data-pc-direction=rtl] .pc-container {
  margin-right: 280px;
  margin-left: 0px;
}
@media (max-width: 1024px) {
  [data-pc-direction=rtl] .pc-container {
    margin-right: 0px;
  }
}
[data-pc-direction=rtl] .pct-c-btn a {
  padding: 12px 12px 12px 16px;
}
[data-pc-direction=rtl] .pc-sidebar {
  border-left: var(--pc-sidebar-border);
  border-right: none;
}
[data-pc-direction=rtl] .pc-sidebar ul {
  padding-right: 0;
}
[data-pc-direction=rtl] .pc-sidebar .pc-badge,
[data-pc-direction=rtl] .pc-sidebar .pc-arrow {
  float: left;
}
[data-pc-direction=rtl] .pc-sidebar .pc-micon {
  margin-right: 0;
  margin-left: 15px;
}
@media (min-width: 1025px) {
  [data-pc-direction=rtl] .pc-sidebar.pc-sidebar-hide {
    transform: translateX(260px);
  }
  [data-pc-direction=rtl] .pc-sidebar.pc-sidebar-hide ~ .pc-footer,
  [data-pc-direction=rtl] .pc-sidebar.pc-sidebar-hide ~ .pc-container {
    margin-right: 0px;
  }
  [data-pc-direction=rtl] .pc-sidebar.pc-sidebar-hide ~ .pc-header {
    right: 0;
  }
}
@media (max-width: 1024px) {
  [data-pc-direction=rtl] .pc-sidebar {
    left: auto;
    right: -280px;
  }
  [data-pc-direction=rtl] .pc-sidebar.mob-sidebar-active {
    right: 0;
  }
  [data-pc-direction=rtl] .pc-sidebar ~ .pc-header {
    right: 0;
  }
}
[data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu:after {
  left: auto;
  right: 30px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-link {
  padding: 12px 60px 12px 30px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-link:after {
  left: auto;
  right: 45px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu:after {
  left: auto;
  right: 46px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-link {
  padding: 12px 80px 12px 30px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-link:after {
  left: auto;
  right: 62px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-submenu:after {
  left: auto;
  right: 63px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-submenu .pc-link {
  padding: 12px 95px 12px 30px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-submenu .pc-submenu .pc-submenu .pc-link:after {
  left: auto;
  right: 79px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-navbar > .pc-item .pc-navbar > li > .pc-submenu::before {
  right: 40px;
  left: auto;
}
[data-pc-direction=rtl] .pc-header {
  left: 0;
  right: 280px;
}
[data-pc-direction=rtl] .pc-header ul {
  padding-right: 0;
}
[data-pc-direction=rtl] .pc-header .m-header {
  padding: 16px 24px 16px 10px;
}
[data-pc-direction=rtl] .pc-header .user-avtar {
  margin-right: 0;
  margin-left: 10px;
}
@media (max-width: 575.98px) {
  [data-pc-direction=rtl] .pc-header .user-avtar {
    margin-left: 0;
  }
}
[data-pc-direction=rtl] .pc-header .form-search .icon-search {
  left: auto;
  right: 15px;
}
[data-pc-direction=rtl] .pc-header .form-search .btn-search {
  right: auto;
  left: 9px;
}
[data-pc-direction=rtl] .pc-header .pc-h-dropdown:not(.dropdown-menu-end) {
  right: 0 !important;
  left: auto !important;
}
[data-pc-direction=rtl] .pc-header .pc-h-dropdown.dropdown-menu-end {
  right: auto !important;
  left: 0 !important;
}
[data-pc-direction=rtl] .pc-header .dropdown-user-profile .upgradeplan-block::before {
  right: auto;
  left: -70px;
}
[data-pc-direction=rtl] .pc-header .dropdown-user-profile .upgradeplan-block::after {
  right: auto;
  left: -150px;
}
[data-pc-direction=rtl] .pc-header .dropdown-user-profile .settings-block .form-switch {
  padding-left: 2.5em;
}
[data-pc-direction=rtl] .pc-header .dropdown-user-profile .settings-block .form-switch .form-check-input {
  float: left;
}
[data-pc-direction=rtl] .page-header h5,
[data-pc-direction=rtl] .page-header .h5 {
  border-left: 1px solid #d1deea;
  border-right: none;
  margin-right: 0;
  padding-right: 0;
  margin-left: 8px;
  padding-left: 8px;
}
[data-pc-direction=rtl] .pc-footer {
  margin-left: 0px;
  margin-right: 280px;
}
@media (max-width: 1024px) {
  [data-pc-direction=rtl] .pc-footer {
    margin-right: 0px;
  }
}
[data-pc-direction=rtl] .social-widget-card i {
  right: auto;
  left: 14px;
}
[data-pc-direction=rtl] .table tr td .row {
  margin-right: 0;
  margin-left: 50px;
}
[data-pc-direction=rtl] .task-card li {
  padding-left: 0;
  padding-right: 30px;
}
[data-pc-direction=rtl] .task-card li .task-icon {
  right: 3px;
  left: auto;
}
[data-pc-direction=rtl] .task-card .task-list:after {
  right: 10px;
  left: auto;
}
[data-pc-direction=rtl] .task-card .task-list:before {
  right: 3px;
  left: auto;
}
[data-pc-direction=rtl] .new-cust-card .align-middle .status {
  left: 0;
  right: auto;
}
[data-pc-direction=rtl] .apexcharts-legend-marker {
  margin-left: 3px;
}
[data-pc-direction=rtl] .btn-group .btn {
  border-radius: var(--bs-btn-border-radius) !important;
}
[data-pc-direction=rtl] .btn-group .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
[data-pc-direction=rtl] .btn-group .btn:not(:first-child) {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
[data-pc-direction=rtl] .btn-group .btn:first-of-type {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
[data-pc-direction=rtl] .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
[data-pc-direction=rtl] .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
[data-pc-direction=rtl] .btn-page .btn-group .btn.btn-outline-secondary:last-child {
  border-left: 1px solid #39465f;
  border-right: none;
}
[data-pc-direction=rtl] .btn-page .btn-group .btn:first-child {
  border-left: none;
}
[data-pc-direction=rtl] .btn-page .btn-group .btn.btn-outline-danger:last-child {
  border-left: 1px solid #dc3545;
}
[data-pc-direction=rtl] .btn-page .btn-group label.btn-outline-secondary:first-of-type {
  border-right: 1px solid #39465f;
  border-left: none;
}
[data-pc-direction=rtl] ul.pagination {
  padding-right: 0;
}
[data-pc-direction=rtl] .page-item:not(:first-child) .page-link {
  margin-left: 0;
  margin-right: -1px;
}
[data-pc-direction=rtl] .page-item:last-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
[data-pc-direction=rtl] .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}
[data-pc-direction=rtl] .dropdown-toggle::after {
  margin-left: 0;
  margin-right: 0.255em;
}
[data-pc-direction=rtl] .modal-header .btn-close {
  margin: calc(var(--bs-modal-header-padding-y) * -0.5) auto calc(var(--bs-modal-header-padding-x) * -0.5) calc(var(--bs-modal-header-padding-y) * -0.5);
}
[data-pc-direction=rtl] .toast-header .btn-close {
  margin-right: var(--bs-toast-padding-x);
  margin-left: calc(var(--bs-toast-padding-x) * -0.5);
}
[data-pc-direction=rtl] .notifier-container {
  right: auto;
  left: 4px;
}
[data-pc-direction=rtl] .notifier-container .notifier:not(.shown) {
  transform: translateX(-100%);
}
[data-pc-direction=rtl] .vtree,
[data-pc-direction=rtl] pre[class*=language-] > code {
  direction: ltr;
  text-align: left;
}
[data-pc-direction=rtl] select.form-control,
[data-pc-direction=rtl] select.custom-select,
[data-pc-direction=rtl] select.dataTable-selector,
[data-pc-direction=rtl] select.dataTable-input,
[data-pc-direction=rtl] .form-select {
  background-position: left 0.75rem center;
}
[data-pc-direction=rtl] .form-check {
  padding-left: 0;
  padding-right: 1.75em;
}
[data-pc-direction=rtl] .form-check .form-check-input {
  float: right;
  margin-right: -1.75em;
}
[data-pc-direction=rtl] .address-check .form-check {
  padding-right: 0;
}
[data-pc-direction=rtl] .address-btns {
  justify-content: flex-end !important;
}
[data-pc-direction=rtl] select.custom-select,
[data-pc-direction=rtl] select.dataTable-selector,
[data-pc-direction=rtl] .form-select {
  padding-left: 2rem;
  padding-right: 0.75rem;
}
[data-pc-direction=rtl] .form-switch {
  padding-left: 0;
  padding-right: 2.5em;
}
[data-pc-direction=rtl] .form-switch .form-check-input {
  margin-right: -2.5em;
  background-position: right center;
}
[data-pc-direction=rtl] .form-switch .form-check-input:checked {
  background-position: left center;
}
[data-pc-direction=rtl] .custom-switch-v1.form-switch {
  padding-right: 2.9em;
}
[data-pc-direction=rtl] .custom-switch-v1.form-switch .form-check-input {
  margin-right: -2.9em;
}
[data-pc-direction=rtl] .input-group > * {
  border-radius: 8px !important;
}
[data-pc-direction=rtl] .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
[data-pc-direction=rtl] .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
[data-pc-direction=rtl] .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
[data-pc-direction=rtl] .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
[data-pc-direction=rtl] .input-group > :not(:first-child):not(.dropdown-menu):not(.form-floating),
[data-pc-direction=rtl] .input-group > .form-floating:not(:first-child) > .form-control,
[data-pc-direction=rtl] .input-group > .form-floating:not(:first-child) > .form-select {
  margin-right: -1px;
  margin-left: 0;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
[data-pc-direction=rtl] .form-floating > label {
  width: auto;
  left: auto;
  right: -10px;
}
[data-pc-direction=rtl] .form-check-inline {
  margin-right: 0;
  margin-left: 1rem;
}
[data-pc-direction=rtl] .choices[data-type*=select-multiple] .choices__button,
[data-pc-direction=rtl] .choices[data-type*=text] .choices__button {
  margin: 0 8px 0 -4px;
  border-left: none;
  border-right: 1px solid rgba(255, 255, 255, 0.35);
}
[data-pc-direction=rtl] .choices[data-type*=select-one]:after {
  right: auto;
  left: 11.5px;
}
[data-pc-direction=rtl] .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: auto;
  left: 0;
}
[data-pc-direction=rtl] .editor-toolbar,
[data-pc-direction=rtl] .CodeMirror {
  direction: ltr;
  text-align: left;
}
[data-pc-direction=rtl] .dataTable-sorter::before,
[data-pc-direction=rtl] .dataTable-sorter::after {
  right: auto;
  left: 4px;
}
[data-pc-direction=rtl] .dataTable-dropdown label select,
[data-pc-direction=rtl] .datatable-dropdown label select {
  margin-right: 0;
  margin-left: 8px;
}
[data-pc-direction=rtl] .dataTables_length select.form-select {
  padding-right: 0.7rem;
  padding-left: 30px;
}
[data-pc-direction=rtl] .dataTables_scrollHeadInner,
[data-pc-direction=rtl] .dataTables_scrollFootInner {
  padding-right: 0 !important;
  width: calc(100% - 17px) !important;
}
[data-pc-direction=rtl] table.dataTable thead th,
[data-pc-direction=rtl] table.dataTable thead td,
[data-pc-direction=rtl] table.dataTable tfoot th,
[data-pc-direction=rtl] table.dataTable tfoot td {
  text-align: right;
}
[data-pc-direction=rtl] table.dataTable .form-control {
  padding-left: 30px;
  padding-right: 0.75rem;
}
[data-pc-direction=rtl] table.dataTable thead > tr > th.dt-orderable-asc, [data-pc-direction=rtl] table.dataTable thead > tr > th.dt-orderable-desc, [data-pc-direction=rtl] table.dataTable thead > tr > th.dt-ordering-asc, [data-pc-direction=rtl] table.dataTable thead > tr > th.dt-ordering-desc,
[data-pc-direction=rtl] table.dataTable thead > tr > td.dt-orderable-asc,
[data-pc-direction=rtl] table.dataTable thead > tr > td.dt-orderable-desc,
[data-pc-direction=rtl] table.dataTable thead > tr > td.dt-ordering-asc,
[data-pc-direction=rtl] table.dataTable thead > tr > td.dt-ordering-desc {
  padding-right: 12px;
  padding-left: 30px;
}
[data-pc-direction=rtl] table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order, [data-pc-direction=rtl] table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order, [data-pc-direction=rtl] table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order, [data-pc-direction=rtl] table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order,
[data-pc-direction=rtl] table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order,
[data-pc-direction=rtl] table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order,
[data-pc-direction=rtl] table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order,
[data-pc-direction=rtl] table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order {
  right: unset;
  left: 12px;
}
[data-pc-direction=rtl] .dt-scroll-headInner,
[data-pc-direction=rtl] .dt-scroll-footInner {
  padding-right: unset !important;
  padding-left: 17px;
}
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting:before,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting:after,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_asc:before,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_asc:after,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_desc:before,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_desc:after,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_asc_disabled:before,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_asc_disabled:after,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_desc_disabled:before,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_desc_disabled:after,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting:before,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting:after,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_asc:before,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_asc:after,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_desc:before,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_desc:after,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_asc_disabled:before,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_asc_disabled:after,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_desc_disabled:before,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_desc_disabled:after {
  left: 10px;
  right: unset;
}
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_asc,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_desc,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_asc_disabled,
[data-pc-direction=rtl] table.dataTable thead > tr > th.sorting_desc_disabled,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_asc,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_desc,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_asc_disabled,
[data-pc-direction=rtl] table.dataTable thead > tr > td.sorting_desc_disabled {
  padding-right: 0.75rem;
  padding-left: 26px;
}
[data-pc-direction=rtl] div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0;
  margin-right: 0.5em;
}
[data-pc-direction=rtl] div.dataTables_wrapper div.dataTables_filter {
  text-align: left;
}
[data-pc-direction=rtl] div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:first-child {
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}
[data-pc-direction=rtl] div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:last-child {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
}
[data-pc-direction=rtl] div.table-responsive > div.dt-container > div.row > div[class^=col-]:first-child {
  padding-right: 0;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}
@media (max-width: 767px) {
  [data-pc-direction=rtl] div.dataTables_wrapper div.dataTables_length,
  [data-pc-direction=rtl] div.dataTables_wrapper div.dataTables_filter,
  [data-pc-direction=rtl] div.dataTables_wrapper div.dataTables_info,
  [data-pc-direction=rtl] div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
}
[data-pc-direction=rtl] table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control,
[data-pc-direction=rtl] table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control {
  padding-left: 0.75rem;
  padding-right: 30px;
}
[data-pc-direction=rtl] table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before,
[data-pc-direction=rtl] table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control:before {
  left: auto;
  right: 5px;
}
[data-pc-direction=rtl] .datatable-table > thead > tr > th {
  text-align: right;
}
[data-pc-direction=rtl] .datatable-table .datatable-sorter {
  padding-right: unset;
  padding-left: 18px;
}
[data-pc-direction=rtl] .datatable-table .datatable-sorter::before, [data-pc-direction=rtl] .datatable-table .datatable-sorter::after {
  right: unset;
  left: 4px;
}
[data-pc-direction=rtl] .datatable-top > nav:first-child,
[data-pc-direction=rtl] .datatable-top > div:first-child {
  float: right;
}
[data-pc-direction=rtl] .datatable-top > nav:last-child,
[data-pc-direction=rtl] .datatable-top > div:last-child {
  float: left;
}
[data-pc-direction=rtl] div.dt-container div.dt-length select {
  margin-right: unset;
  margin-left: 0.5em;
}
[data-pc-direction=rtl] div.dt-container div.dt-search {
  text-align: left;
}
[data-pc-direction=rtl] div.dt-container div.dt-search input {
  margin-left: unset;
  margin-right: 0.5em;
}
[data-pc-direction=rtl] .pc-icon-checkbox {
  padding-right: 0;
}
[data-pc-direction=rtl] #cke5-inline-demo .demo-row .demo-row__half:first-of-type {
  padding-right: 0;
  padding-left: 0.5rem;
}
[data-pc-direction=rtl] #cke5-inline-demo .demo-row .demo-row__half:last-of-type {
  padding-right: 0.5rem;
  padding-left: 0;
}
[data-pc-direction=rtl] .mail-wrapper .mail-menulist {
  margin-right: 0;
  margin-left: var(--bs-gutter-x);
}
[data-pc-direction=rtl] .mail-option .mail-buttons {
  right: auto;
  left: 5px;
  transform-origin: left;
}
[data-pc-direction=rtl] .mail-wrapper .mail-menulist .list-group-item-action .material-icons-two-tone {
  margin-left: 8px;
  margin-right: 0;
}
[data-pc-direction=rtl] .mail-wrapper .mail-menulist .list-group-item-action i {
  margin-right: unset;
  margin-left: 8px;
}
[data-pc-direction=rtl] .nav {
  padding-right: 0;
}
[data-pc-direction=rtl] .nav.nav-price {
  padding-right: 12px;
}
[data-pc-direction=rtl] .chat-wrapper .chat-user_list {
  margin-right: 0;
  margin-left: var(--bs-gutter-x);
}
[data-pc-direction=rtl] .chat-wrapper .chat-user_info {
  margin-right: var(--bs-gutter-x);
  margin-left: 0;
}
@media (max-width: 1399.98px) {
  [data-pc-direction=rtl] .chat-wrapper .chat-user_info {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
[data-pc-direction=rtl] .chat-wrapper .chat-user_info .justify-content-start {
  justify-content: flex-start !important;
}
[data-pc-direction=rtl] .table-card .card-body .table tr td:first-child,
[data-pc-direction=rtl] .table-card .card-body .table tr th:first-child,
[data-pc-direction=rtl] .table-body.card-body .table tr td:first-child,
[data-pc-direction=rtl] .table-body.card-body .table tr th:first-child {
  padding-right: 25px;
  padding-left: 0.75rem;
}
@media (max-width: 575.98px) {
  [data-pc-direction=rtl] .table-card .card-body .table tr td:first-child,
  [data-pc-direction=rtl] .table-card .card-body .table tr th:first-child,
  [data-pc-direction=rtl] .table-body.card-body .table tr td:first-child,
  [data-pc-direction=rtl] .table-body.card-body .table tr th:first-child {
    padding-right: 20px;
  }
}
[data-pc-direction=rtl] .table-card .card-body .table tr td:last-child,
[data-pc-direction=rtl] .table-card .card-body .table tr th:last-child,
[data-pc-direction=rtl] .table-body.card-body .table tr td:last-child,
[data-pc-direction=rtl] .table-body.card-body .table tr th:last-child {
  padding-left: 25px;
  padding-right: 0.75rem;
}
@media (max-width: 575.98px) {
  [data-pc-direction=rtl] .table-card .card-body .table tr td:last-child,
  [data-pc-direction=rtl] .table-card .card-body .table tr th:last-child,
  [data-pc-direction=rtl] .table-body.card-body .table tr td:last-child,
  [data-pc-direction=rtl] .table-body.card-body .table tr th:last-child {
    padding-left: 20px;
  }
}
[data-pc-direction=rtl] .tbl-product .prod-action-links {
  left: var(--bs-card-spacer-x);
  right: unset;
}
[data-pc-direction=rtl] .dataTable-table th a {
  margin-left: 16px;
  padding-right: 0;
}
[data-pc-direction=rtl] .list-inline-item:not(:last-child) {
  margin-right: 0;
  margin-left: 0.5rem;
}
[data-pc-direction=rtl] .was-validated .form-select:valid:not([multiple]):not([size]),
[data-pc-direction=rtl] .was-validated .form-select:valid:not([multiple])[size="1"],
[data-pc-direction=rtl] .form-select.is-valid:not([multiple]):not([size]),
[data-pc-direction=rtl] .form-select.is-valid:not([multiple])[size="1"],
[data-pc-direction=rtl] .was-validated .form-select:invalid:not([multiple]):not([size]),
[data-pc-direction=rtl] .was-validated .form-select:invalid:not([multiple])[size="1"],
[data-pc-direction=rtl] .form-select.is-invalid:not([multiple]):not([size]),
[data-pc-direction=rtl] .form-select.is-invalid:not([multiple])[size="1"] {
  background-position: left 0.75rem center, center left 2.75rem;
  padding-right: 0.75rem;
}
[data-pc-direction=rtl] .was-validated textarea.form-control:invalid,
[data-pc-direction=rtl] .was-validated textarea.custom-select:invalid,
[data-pc-direction=rtl] .was-validated textarea.dataTable-selector:invalid,
[data-pc-direction=rtl] .was-validated textarea.dataTable-input:invalid,
[data-pc-direction=rtl] textarea.form-control.is-invalid,
[data-pc-direction=rtl] textarea.is-invalid.custom-select,
[data-pc-direction=rtl] textarea.is-invalid.dataTable-selector,
[data-pc-direction=rtl] textarea.is-invalid.dataTable-input,
[data-pc-direction=rtl] .was-validated textarea.form-control:valid,
[data-pc-direction=rtl] .was-validated textarea.custom-select:valid,
[data-pc-direction=rtl] .was-validated textarea.dataTable-selector:valid,
[data-pc-direction=rtl] .was-validated textarea.dataTable-input:valid,
[data-pc-direction=rtl] textarea.form-control.is-valid,
[data-pc-direction=rtl] textarea.is-valid.custom-select,
[data-pc-direction=rtl] textarea.is-valid.dataTable-selector,
[data-pc-direction=rtl] textarea.is-valid.dataTable-input {
  background-position: top calc(0.375em + 0.4rem) left calc(0.375em + 0.4rem);
  padding-left: calc(1.5em + 1.6rem);
  padding-right: 0.75rem;
}
[data-pc-direction=rtl] .was-validated .form-control:invalid,
[data-pc-direction=rtl] .was-validated .custom-select:invalid,
[data-pc-direction=rtl] .was-validated .dataTable-selector:invalid,
[data-pc-direction=rtl] .was-validated .dataTable-input:invalid,
[data-pc-direction=rtl] .form-control.is-invalid,
[data-pc-direction=rtl] .is-invalid.custom-select,
[data-pc-direction=rtl] .is-invalid.dataTable-selector,
[data-pc-direction=rtl] .is-invalid.dataTable-input,
[data-pc-direction=rtl] .was-validated .form-control:valid,
[data-pc-direction=rtl] .was-validated .custom-select:valid,
[data-pc-direction=rtl] .was-validated .dataTable-selector:valid,
[data-pc-direction=rtl] .was-validated .dataTable-input:valid,
[data-pc-direction=rtl] .form-control.is-valid,
[data-pc-direction=rtl] .is-valid.custom-select,
[data-pc-direction=rtl] .is-valid.dataTable-selector,
[data-pc-direction=rtl] .is-valid.dataTable-input {
  background-position: left calc(0.375em + 0.4rem) center;
  padding-left: calc(1.5em + 1.6rem);
  padding-right: 0.75rem;
}
[data-pc-direction=rtl] .pc-footer .footer-link .list-inline-item:not(:last-child) {
  margin-right: 0;
  margin-left: 0.9rem;
}
[data-pc-direction=rtl] .cropper {
  direction: ltr;
}
[data-pc-direction=rtl] .user-group .avtar + .avtar,
[data-pc-direction=rtl] .user-group .avtar + img,
[data-pc-direction=rtl] .user-group img + .avtar,
[data-pc-direction=rtl] .user-group img + img {
  margin-left: 0;
  margin-right: -14px;
}
[data-pc-direction=rtl] .auth-main .auth-wrapper .auth-form img + span {
  padding-left: 0;
  padding-right: 10px;
}
[data-pc-direction=rtl] .progress-stacked {
  overflow: hidden;
}
[data-pc-direction=rtl] .progress-stacked .progress:not(:first-child) .progress-bar {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
[data-pc-direction=rtl] .progress-stacked .progress:not(:last-child) .progress-bar {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
[data-pc-direction=rtl] .statistics-card-1 .card-body .img-bg {
  right: auto;
  left: 0;
  transform: rotateY(180deg);
}
[data-pc-direction=rtl] .latest-activity-card .card-body .latest-update-box .update-meta:after {
  right: auto;
  left: 32px;
}
[data-pc-direction=rtl] .latest-activity-card .card-body .latest-update-box .update-meta .update-icon {
  margin-left: 0;
  margin-right: 10px;
}
@media only screen and (max-width: 575px) {
  [data-pc-direction=rtl] .latest-activity-card .card-body .latest-update-box .update-meta .update-icon {
    margin-left: 10px;
    margin-right: 0;
  }
}
[data-pc-direction=rtl] .fslightbox-container {
  direction: ltr;
}
[data-pc-direction=rtl] .form-search .icon-search {
  left: auto;
  right: 15px;
}
[data-pc-direction=rtl] .form-search .btn-search {
  right: auto;
  left: 9px;
}
[data-pc-direction=rtl] .account-pills.nav-pills .nav-link.active::after,
[data-pc-direction=rtl] .account-pills.nav-pills .show > .nav-link::after {
  right: auto;
  left: 0;
}
[data-pc-direction=rtl] .acc-feeds-list .feed-title::before {
  right: auto;
  left: 5px;
}
[data-pc-direction=rtl] .acc-feeds-list .feed-title::after {
  right: auto;
  left: 0;
}
@media (min-width: 576px) {
  [data-pc-direction=rtl] .footer-wrapper .justify-content-sm-end {
    justify-content: flex-end !important;
  }
}
[data-pc-direction=rtl] .ecom-wrapper .ecom-filter {
  margin-left: var(--bs-gutter-x);
  margin-right: unset;
}
[data-pc-direction=rtl] .color-checkbox {
  padding: 0;
  margin-left: 8px;
}
[data-pc-direction=rtl] .gallery-hover-data {
  justify-content: flex-end !important;
}
[data-pc-direction=rtl] .element-item .gallery-hover-data {
  justify-content: space-between !important;
}
[data-pc-direction=rtl] .card-img,
[data-pc-direction=rtl] .card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
@media (min-width: 576px) {
  [data-pc-direction=rtl] .card-group > .card:not(:last-child) {
    border-bottom-left-radius: 0;
  }
  [data-pc-direction=rtl] .card-group > .card:not(:first-child) {
    border-bottom-right-radius: 0;
  }
}
[data-pc-direction=rtl] .offcanvas-header .btn-close {
  margin-left: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-right: 0;
}
[data-pc-direction=rtl] .dataTables_scrollHeadInner {
  padding-right: 0 !important;
  padding-left: 17px;
}
[data-pc-direction=rtl] .datatable-table th a {
  text-align: right;
}
[data-pc-direction=rtl] .datatable-pagination ul {
  padding-right: 0;
}
[data-pc-direction=rtl] .uppy-StatusBar-actions {
  left: auto;
  right: 10px;
}
[data-pc-direction=rtl] .typeahead > ul {
  padding-right: 0;
  text-align: right;
}
[data-pc-direction=rtl] .switch {
  margin-right: 0;
  margin-left: 8px;
}
[data-pc-direction=rtl] #tree-msg {
  text-align: left;
}
[data-pc-direction=rtl] .tns-outer {
  direction: ltr;
}
[data-pc-direction=rtl] #BC .slider-handle {
  margin-left: -12px;
  margin-right: auto;
}
[data-pc-direction=rtl] .notifier-close {
  left: 4px;
  right: auto;
}
[data-pc-direction=rtl] .list-group[class*=list-group-horizontal] {
  flex-direction: column;
  justify-content: flex-end;
}
[data-pc-direction=rtl] .list-group.list-group-horizontal {
  flex-direction: row-reverse;
}
@media (min-width: 576px) {
  [data-pc-direction=rtl] .list-group.list-group-horizontal-sm {
    flex-direction: row-reverse;
  }
}
@media (min-width: 768px) {
  [data-pc-direction=rtl] .list-group.list-group-horizontal-md {
    flex-direction: row-reverse;
  }
}
@media (min-width: 992px) {
  [data-pc-direction=rtl] .list-group.list-group-horizontal-lg {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1200px) {
  [data-pc-direction=rtl] .list-group.list-group-horizontal-xl {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1400px) {
  [data-pc-direction=rtl] .list-group.list-group-horizontal-xxl {
    flex-direction: row-reverse;
  }
}
[data-pc-direction=rtl] .auth-main .auth-sidefooter .footer-link li + li {
  padding-left: 0;
  padding-right: 12px;
}
[data-pc-direction=rtl] .auth-main .auth-sidefooter .footer-link li + li:after {
  left: auto;
  right: 0;
}
[data-pc-direction=rtl].layout-nested .pc-submenu-list-wrapper {
  left: auto;
  right: 0;
}
[data-pc-direction=rtl].layout-nested .pc-container .pc-content {
  margin-left: 0px;
  margin-right: calc(280px - 50px);
}
[data-pc-direction=rtl].layout-nested .pc-footer {
  margin-left: 0px;
  margin-right: calc(280px + 230px);
}
@media (max-width: 1024px) {
  [data-pc-direction=rtl].layout-nested .pc-container .pc-content {
    margin-left: 0;
    margin-right: 0;
  }
}

[data-pc-theme=dark] {
  --bs-body-bg: #131920;
  --bs-body-bg-rgb: 19, 25, 32;
  --pc-heading-color: rgba(255, 255, 255, 0.8);
  --pc-sidebar-background: #1d2630;
  --pc-sidebar-color: #ffffff;
  --pc-sidebar-color-rgb: 255, 255, 255;
  --pc-sidebar-submenu-border-color: var(--bs-gray-600);
  --pc-sidebar-caption-color: #748892;
  --pc-header-background: rgba(var(--bs-body-bg-rgb), 0.7);
  --pc-header-color: #6f747f;
  --pc-header-shadow: none;
  --pc-active-background: #19212a;
  --pc-card-box-shadow: 0px 8px 24px rgba(var(--bs-body-bg-rgb), 0.5);
  --pc-header-submenu-background: #263240;
  --pc-header-submenu-color: var(--bs-body-color);
  --bs-heading-color: #fafafa;
  --bs-body-color: #bfbfbf;
  --bs-body-color-rgb: to-rgb(#bfbfbf);
  --bs-border-color: #303f50;
  --ck-color-image-caption-background: #1b232d;
  --ck-color-image-caption-text: #bfbfbf;
}
[data-pc-theme=dark] .text-muted {
  color: #748892 !important;
}
[data-pc-theme=dark] .card {
  --bs-card-border-color: #303f50;
  --bs-card-bg: #1b232d;
}
[data-pc-theme=dark] .offcanvas,
[data-pc-theme=dark] .offcanvas-xxl,
[data-pc-theme=dark] .offcanvas-xl,
[data-pc-theme=dark] .offcanvas-lg,
[data-pc-theme=dark] .offcanvas-md,
[data-pc-theme=dark] .offcanvas-sm {
  --bs-offcanvas-bg: #19212a;
}
[data-pc-theme=dark] .list-group {
  --bs-list-group-bg: #1b232d;
  --bs-list-group-border-color: #202b36;
  --bs-list-group-disabled-bg: #171e26;
  --bs-list-group-action-hover-bg: #1d2630;
  --bs-list-group-action-hover-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: #1d2630;
  --bs-list-group-action-active-color: var(--bs-body-color);
}
[data-pc-theme=dark] .preset-btn {
  --bs-gray-300: #1d2630;
}
[data-pc-theme=dark] .progress {
  --bs-progress-bg: #1d2630;
}
[data-pc-theme=dark] .btn:not(.btn-light) {
  --bs-btn-color: var(--bs-heading-color);
}
[data-pc-theme=dark] .btn-link-dark,
[data-pc-theme=dark] .btn-link-secondary,
[data-pc-theme=dark] .bg-light-secondary,
[data-pc-theme=dark] .btn-light-secondary {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .dropdown-menu {
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-bg: #263240;
  --bs-dropdown-link-hover-bg: #19212a;
  --bs-dropdown-divider-bg: #1d2630;
}
[data-pc-theme=dark] .pagination {
  --bs-pagination-bg: #19212a;
  --bs-pagination-border-color: #202b36;
  --bs-pagination-hover-bg: #1e2833;
  --bs-pagination-hover-border-color: #202b36;
  --bs-pagination-focus-bg: #1e2833;
  --bs-pagination-disabled-bg: #151c23;
  --bs-pagination-disabled-border-color: #202b36;
}
[data-pc-theme=dark] .accordion-item {
  --bs-accordion-bg: #19212a;
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-btn-bg: #19212a;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-active-bg: rgba(var(--bs-primary-rgb), 0.2);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23dbe0e5'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-theme=dark] .navbar.navbar-light .navbar-nav {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
[data-pc-theme=dark] .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 16 16'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}
[data-pc-theme=dark] .btn-close.btn-close-white {
  filter: none;
}
[data-pc-theme=dark] .modal {
  --bs-modal-bg: #1b232d;
  --bs-modal-header-border-color: #202b36;
  --bs-modal-footer-border-color: #202b36;
}
[data-pc-theme=dark] .toast {
  --bs-toast-bg: #1b232d;
  --bs-toast-color: var(--bs-body-color);
  --bs-toast-header-bg: #1e2833;
  --bs-toast-header-color: var(--bs-heading-color);
}
[data-pc-theme=dark] .vtree a.vtree-leaf-label {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .vtree li.vtree-leaf a.vtree-leaf-label:hover,
[data-pc-theme=dark] .vtree li.vtree-leaf.vtree-selected > a.vtree-leaf-label {
  background-color: #222d3a;
  outline-color: #222d3a;
}
[data-pc-theme=dark] .custom-select,
[data-pc-theme=dark] .datatable-selector,
[data-pc-theme=dark] .datatable-input,
[data-pc-theme=dark] .form-select,
[data-pc-theme=dark] .form-control {
  background-color: #263240;
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .custom-select:not(:focus),
[data-pc-theme=dark] .datatable-selector:not(:focus),
[data-pc-theme=dark] .datatable-input:not(:focus),
[data-pc-theme=dark] .form-select:not(:focus),
[data-pc-theme=dark] .form-control:not(:focus) {
  border-color: #303f50;
}
[data-pc-theme=dark] .custom-select.is-valid,
[data-pc-theme=dark] .datatable-selector.is-valid,
[data-pc-theme=dark] .datatable-input.is-valid,
[data-pc-theme=dark] .form-select.is-valid,
[data-pc-theme=dark] .form-control.is-valid {
  border-color: var(--bs-success);
}
[data-pc-theme=dark] .custom-select.is-invalid,
[data-pc-theme=dark] .datatable-selector.is-invalid,
[data-pc-theme=dark] .datatable-input.is-invalid,
[data-pc-theme=dark] .form-select.is-invalid,
[data-pc-theme=dark] .form-control.is-invalid {
  border-color: var(--bs-danger);
}
[data-pc-theme=dark] .form-control-plaintext {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .form-check-input:not(:checked),
[data-pc-theme=dark] .input-group-text {
  background-color: #263240;
  color: var(--bs-body-color);
  border-color: #303f50;
}
[data-pc-theme=dark] .form-control::file-selector-button {
  background-color: #263240;
  color: var(--bs-body-color);
  border-color: #303f50;
}
[data-pc-theme=dark] .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #202b36;
}
[data-pc-theme=dark] select.form-control,
[data-pc-theme=dark] select.custom-select,
[data-pc-theme=dark] select.datatable-selector,
[data-pc-theme=dark] select.datatable-input,
[data-pc-theme=dark] .form-select:not([multiple]) {
  background-color: #263240;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%236f747f' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 16px 12px;
}
[data-pc-theme=dark] .form-range::-webkit-slider-runnable-track {
  background-color: #263240;
}
[data-pc-theme=dark] .drp-search .form-control {
  background: transparent;
}
[data-pc-theme=dark] .loader {
  background-color: rgba(19, 25, 32, 0.5);
}
[data-pc-theme=dark] .text-dark {
  color: var(--bs-body-color) !important;
}
[data-pc-theme=dark] .carousel-dark .carousel-caption h5 {
  color: #000000;
}
[data-pc-theme=dark] .alert-primary {
  color: #0d6efd;
  background: rgba(13, 110, 253, 0.2);
  border-color: rgba(13, 110, 253, 0.2);
}
[data-pc-theme=dark] .alert-primary .alert-link {
  color: #0d6efd;
}
[data-pc-theme=dark] .badge.bg-light-primary {
  background: rgba(13, 110, 253, 0.2);
  color: #0d6efd;
  border-color: rgba(13, 110, 253, 0.2);
}
[data-pc-theme=dark] .icon-svg-primary {
  fill: rgba(13, 110, 253, 0.2);
  stroke: #0d6efd;
}
[data-pc-theme=dark] .bg-light-primary {
  background: rgba(13, 110, 253, 0.2);
}
[data-pc-theme=dark] .btn-light-primary:not(:hover) {
  background: rgba(13, 110, 253, 0.2) !important;
  border-color: rgba(13, 110, 253, 0.2);
}
[data-pc-theme=dark] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-link-primary:not(:disabled):not(.disabled):active, [data-pc-theme=dark] .btn-link-primary:active, [data-pc-theme=dark] .btn-link-primary:focus, [data-pc-theme=dark] .btn-link-primary:hover {
  background: rgba(13, 110, 253, 0.2);
  border-color: rgba(13, 110, 253, 0.2);
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(13, 110, 253, 0.2);
  background-color: rgba(13, 110, 253, 0.2);
}
[data-pc-theme=dark] .alert-secondary {
  color: #5b6b79;
  background: rgba(91, 107, 121, 0.2);
  border-color: rgba(91, 107, 121, 0.2);
}
[data-pc-theme=dark] .alert-secondary .alert-link {
  color: #5b6b79;
}
[data-pc-theme=dark] .badge.bg-light-secondary {
  background: rgba(91, 107, 121, 0.2);
  color: #5b6b79;
  border-color: rgba(91, 107, 121, 0.2);
}
[data-pc-theme=dark] .icon-svg-secondary {
  fill: rgba(91, 107, 121, 0.2);
  stroke: #5b6b79;
}
[data-pc-theme=dark] .bg-light-secondary {
  background: rgba(91, 107, 121, 0.2);
}
[data-pc-theme=dark] .btn-light-secondary:not(:hover) {
  background: rgba(91, 107, 121, 0.2) !important;
  border-color: rgba(91, 107, 121, 0.2);
}
[data-pc-theme=dark] .btn-link-secondary:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-link-secondary:not(:disabled):not(.disabled):active, [data-pc-theme=dark] .btn-link-secondary:active, [data-pc-theme=dark] .btn-link-secondary:focus, [data-pc-theme=dark] .btn-link-secondary:hover {
  background: rgba(91, 107, 121, 0.2);
  border-color: rgba(91, 107, 121, 0.2);
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-secondary:checked {
  border-color: rgba(91, 107, 121, 0.2);
  background-color: rgba(91, 107, 121, 0.2);
}
[data-pc-theme=dark] .alert-success {
  color: #198754;
  background: rgba(25, 135, 84, 0.2);
  border-color: rgba(25, 135, 84, 0.2);
}
[data-pc-theme=dark] .alert-success .alert-link {
  color: #198754;
}
[data-pc-theme=dark] .badge.bg-light-success {
  background: rgba(25, 135, 84, 0.2);
  color: #198754;
  border-color: rgba(25, 135, 84, 0.2);
}
[data-pc-theme=dark] .icon-svg-success {
  fill: rgba(25, 135, 84, 0.2);
  stroke: #198754;
}
[data-pc-theme=dark] .bg-light-success {
  background: rgba(25, 135, 84, 0.2);
}
[data-pc-theme=dark] .btn-light-success:not(:hover) {
  background: rgba(25, 135, 84, 0.2) !important;
  border-color: rgba(25, 135, 84, 0.2);
}
[data-pc-theme=dark] .btn-link-success:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-link-success:not(:disabled):not(.disabled):active, [data-pc-theme=dark] .btn-link-success:active, [data-pc-theme=dark] .btn-link-success:focus, [data-pc-theme=dark] .btn-link-success:hover {
  background: rgba(25, 135, 84, 0.2);
  border-color: rgba(25, 135, 84, 0.2);
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-success:checked {
  border-color: rgba(25, 135, 84, 0.2);
  background-color: rgba(25, 135, 84, 0.2);
}
[data-pc-theme=dark] .alert-info {
  color: #0dcaf0;
  background: rgba(13, 202, 240, 0.2);
  border-color: rgba(13, 202, 240, 0.2);
}
[data-pc-theme=dark] .alert-info .alert-link {
  color: #0dcaf0;
}
[data-pc-theme=dark] .badge.bg-light-info {
  background: rgba(13, 202, 240, 0.2);
  color: #0dcaf0;
  border-color: rgba(13, 202, 240, 0.2);
}
[data-pc-theme=dark] .icon-svg-info {
  fill: rgba(13, 202, 240, 0.2);
  stroke: #0dcaf0;
}
[data-pc-theme=dark] .bg-light-info {
  background: rgba(13, 202, 240, 0.2);
}
[data-pc-theme=dark] .btn-light-info:not(:hover) {
  background: rgba(13, 202, 240, 0.2) !important;
  border-color: rgba(13, 202, 240, 0.2);
}
[data-pc-theme=dark] .btn-link-info:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-link-info:not(:disabled):not(.disabled):active, [data-pc-theme=dark] .btn-link-info:active, [data-pc-theme=dark] .btn-link-info:focus, [data-pc-theme=dark] .btn-link-info:hover {
  background: rgba(13, 202, 240, 0.2);
  border-color: rgba(13, 202, 240, 0.2);
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-info:checked {
  border-color: rgba(13, 202, 240, 0.2);
  background-color: rgba(13, 202, 240, 0.2);
}
[data-pc-theme=dark] .alert-warning {
  color: #ffc107;
  background: rgba(255, 193, 7, 0.2);
  border-color: rgba(255, 193, 7, 0.2);
}
[data-pc-theme=dark] .alert-warning .alert-link {
  color: #ffc107;
}
[data-pc-theme=dark] .badge.bg-light-warning {
  background: rgba(255, 193, 7, 0.2);
  color: #ffc107;
  border-color: rgba(255, 193, 7, 0.2);
}
[data-pc-theme=dark] .icon-svg-warning {
  fill: rgba(255, 193, 7, 0.2);
  stroke: #ffc107;
}
[data-pc-theme=dark] .bg-light-warning {
  background: rgba(255, 193, 7, 0.2);
}
[data-pc-theme=dark] .btn-light-warning:not(:hover) {
  background: rgba(255, 193, 7, 0.2) !important;
  border-color: rgba(255, 193, 7, 0.2);
}
[data-pc-theme=dark] .btn-link-warning:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-link-warning:not(:disabled):not(.disabled):active, [data-pc-theme=dark] .btn-link-warning:active, [data-pc-theme=dark] .btn-link-warning:focus, [data-pc-theme=dark] .btn-link-warning:hover {
  background: rgba(255, 193, 7, 0.2);
  border-color: rgba(255, 193, 7, 0.2);
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-warning:checked {
  border-color: rgba(255, 193, 7, 0.2);
  background-color: rgba(255, 193, 7, 0.2);
}
[data-pc-theme=dark] .alert-danger {
  color: #dc3545;
  background: rgba(220, 53, 69, 0.2);
  border-color: rgba(220, 53, 69, 0.2);
}
[data-pc-theme=dark] .alert-danger .alert-link {
  color: #dc3545;
}
[data-pc-theme=dark] .badge.bg-light-danger {
  background: rgba(220, 53, 69, 0.2);
  color: #dc3545;
  border-color: rgba(220, 53, 69, 0.2);
}
[data-pc-theme=dark] .icon-svg-danger {
  fill: rgba(220, 53, 69, 0.2);
  stroke: #dc3545;
}
[data-pc-theme=dark] .bg-light-danger {
  background: rgba(220, 53, 69, 0.2);
}
[data-pc-theme=dark] .btn-light-danger:not(:hover) {
  background: rgba(220, 53, 69, 0.2) !important;
  border-color: rgba(220, 53, 69, 0.2);
}
[data-pc-theme=dark] .btn-link-danger:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-link-danger:not(:disabled):not(.disabled):active, [data-pc-theme=dark] .btn-link-danger:active, [data-pc-theme=dark] .btn-link-danger:focus, [data-pc-theme=dark] .btn-link-danger:hover {
  background: rgba(220, 53, 69, 0.2);
  border-color: rgba(220, 53, 69, 0.2);
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-danger:checked {
  border-color: rgba(220, 53, 69, 0.2);
  background-color: rgba(220, 53, 69, 0.2);
}
[data-pc-theme=dark] .alert-light {
  color: #f8f9fa;
  background: rgba(248, 249, 250, 0.2);
  border-color: rgba(248, 249, 250, 0.2);
}
[data-pc-theme=dark] .alert-light .alert-link {
  color: #f8f9fa;
}
[data-pc-theme=dark] .badge.bg-light-light {
  background: rgba(248, 249, 250, 0.2);
  color: #f8f9fa;
  border-color: rgba(248, 249, 250, 0.2);
}
[data-pc-theme=dark] .icon-svg-light {
  fill: rgba(248, 249, 250, 0.2);
  stroke: #f8f9fa;
}
[data-pc-theme=dark] .bg-light-light {
  background: rgba(248, 249, 250, 0.2);
}
[data-pc-theme=dark] .btn-light-light:not(:hover) {
  background: rgba(248, 249, 250, 0.2) !important;
  border-color: rgba(248, 249, 250, 0.2);
}
[data-pc-theme=dark] .btn-link-light:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-link-light:not(:disabled):not(.disabled):active, [data-pc-theme=dark] .btn-link-light:active, [data-pc-theme=dark] .btn-link-light:focus, [data-pc-theme=dark] .btn-link-light:hover {
  background: rgba(248, 249, 250, 0.2);
  border-color: rgba(248, 249, 250, 0.2);
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-light:checked {
  border-color: rgba(248, 249, 250, 0.2);
  background-color: rgba(248, 249, 250, 0.2);
}
[data-pc-theme=dark] .alert-dark {
  color: #131920;
  background: rgba(19, 25, 32, 0.2);
  border-color: rgba(19, 25, 32, 0.2);
  color: var(--bs-body-color);
  background-color: #263240;
  border-color: #303f50;
}
[data-pc-theme=dark] .alert-dark .alert-link {
  color: #131920;
}
[data-pc-theme=dark] .alert-dark .alert-link {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .badge.bg-light-dark {
  background: rgba(19, 25, 32, 0.2);
  color: #131920;
  border-color: rgba(19, 25, 32, 0.2);
}
[data-pc-theme=dark] .icon-svg-dark {
  fill: rgba(19, 25, 32, 0.2);
  stroke: #131920;
}
[data-pc-theme=dark] .bg-light-dark {
  background: rgba(19, 25, 32, 0.2);
}
[data-pc-theme=dark] .btn-light-dark:not(:hover) {
  background: rgba(19, 25, 32, 0.2) !important;
  border-color: rgba(19, 25, 32, 0.2);
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .btn-link-dark:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-link-dark:not(:disabled):not(.disabled):active, [data-pc-theme=dark] .btn-link-dark:active, [data-pc-theme=dark] .btn-link-dark:focus, [data-pc-theme=dark] .btn-link-dark:hover {
  background: rgba(19, 25, 32, 0.2);
  border-color: rgba(19, 25, 32, 0.2);
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-dark:checked {
  border-color: rgba(19, 25, 32, 0.2);
  background-color: rgba(19, 25, 32, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-dark:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-dark:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
[data-pc-theme=dark] .custom-switch-v1.form-check .form-check-input.input-light-dark:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%2334495E'/%3e%3c/svg%3e");
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-dark:checked[type=checkbox],
[data-pc-theme=dark] .form-check .form-check-input.input-light-dark:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
[data-pc-theme=dark] .form-floating > .form-control:focus ~ label::after,
[data-pc-theme=dark] .form-floating > .custom-select:focus ~ label::after,
[data-pc-theme=dark] .form-floating > .form-control:not(:placeholder-shown) ~ label::after,
[data-pc-theme=dark] .form-floating > .custom-select:not(:placeholder-shown) ~ label::after,
[data-pc-theme=dark] .form-floating > .form-control-plaintext ~ label::after,
[data-pc-theme=dark] .form-floating > .form-select ~ label::after {
  background: #263240;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(4, 169, 245, 0.2);
  background-color: rgba(4, 169, 245, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-light-primary {
  background: rgba(4, 169, 245, 0.2);
  color: #04a9f5;
  border-color: rgba(4, 169, 245, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-light-primary .material-icons-two-tone {
  background-color: #04a9f5;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-light-primary:hover {
  background: #04a9f5;
  color: #fff;
  border-color: #04a9f5;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-light-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-1] .btn-light-primary:focus {
  background: #04a9f5;
  color: #fff;
  border-color: #04a9f5;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-1] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-1] .btn-light-primary.dropdown-toggle {
  background: #04a9f5;
  color: #fff;
  border-color: #04a9f5;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-check:active + .btn-light-primary,
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-check:checked + .btn-light-primary {
  background: #04a9f5 !important;
  color: #fff;
  border-color: #04a9f5;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-link-primary {
  background: transparent;
  color: #04a9f5;
  border-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-link-primary .material-icons-two-tone {
  background-color: #04a9f5;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-link-primary:hover {
  background: rgba(4, 169, 245, 0.2);
  color: #04a9f5;
  border-color: rgba(4, 169, 245, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-link-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-1] .btn-link-primary:focus {
  background: rgba(4, 169, 245, 0.2);
  color: #04a9f5;
  border-color: rgba(4, 169, 245, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-1] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-1] .btn-link-primary.dropdown-toggle {
  background: rgba(4, 169, 245, 0.2);
  color: #04a9f5;
  border-color: rgba(4, 169, 245, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-check:active + .btn-link-primary,
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-check:checked + .btn-link-primary {
  background: rgba(4, 169, 245, 0.2);
  color: #04a9f5;
  border-color: rgba(4, 169, 245, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(102, 16, 242, 0.2);
  background-color: rgba(102, 16, 242, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-light-primary {
  background: rgba(102, 16, 242, 0.2);
  color: #6610f2;
  border-color: rgba(102, 16, 242, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-light-primary .material-icons-two-tone {
  background-color: #6610f2;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-light-primary:hover {
  background: #6610f2;
  color: #fff;
  border-color: #6610f2;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-light-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-2] .btn-light-primary:focus {
  background: #6610f2;
  color: #fff;
  border-color: #6610f2;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-2] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-2] .btn-light-primary.dropdown-toggle {
  background: #6610f2;
  color: #fff;
  border-color: #6610f2;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-check:active + .btn-light-primary,
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-check:checked + .btn-light-primary {
  background: #6610f2 !important;
  color: #fff;
  border-color: #6610f2;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-link-primary {
  background: transparent;
  color: #6610f2;
  border-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-link-primary .material-icons-two-tone {
  background-color: #6610f2;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-link-primary:hover {
  background: rgba(102, 16, 242, 0.2);
  color: #6610f2;
  border-color: rgba(102, 16, 242, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-link-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-2] .btn-link-primary:focus {
  background: rgba(102, 16, 242, 0.2);
  color: #6610f2;
  border-color: rgba(102, 16, 242, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-2] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-2] .btn-link-primary.dropdown-toggle {
  background: rgba(102, 16, 242, 0.2);
  color: #6610f2;
  border-color: rgba(102, 16, 242, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-check:active + .btn-link-primary,
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-check:checked + .btn-link-primary {
  background: rgba(102, 16, 242, 0.2);
  color: #6610f2;
  border-color: rgba(102, 16, 242, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(103, 58, 183, 0.2);
  background-color: rgba(103, 58, 183, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-light-primary {
  background: rgba(103, 58, 183, 0.2);
  color: #673ab7;
  border-color: rgba(103, 58, 183, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-light-primary .material-icons-two-tone {
  background-color: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-light-primary:hover {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-light-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-3] .btn-light-primary:focus {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-3] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-3] .btn-light-primary.dropdown-toggle {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-check:active + .btn-light-primary,
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-check:checked + .btn-light-primary {
  background: #673ab7 !important;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-link-primary {
  background: transparent;
  color: #673ab7;
  border-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-link-primary .material-icons-two-tone {
  background-color: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-link-primary:hover {
  background: rgba(103, 58, 183, 0.2);
  color: #673ab7;
  border-color: rgba(103, 58, 183, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-link-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-3] .btn-link-primary:focus {
  background: rgba(103, 58, 183, 0.2);
  color: #673ab7;
  border-color: rgba(103, 58, 183, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-3] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-3] .btn-link-primary.dropdown-toggle {
  background: rgba(103, 58, 183, 0.2);
  color: #673ab7;
  border-color: rgba(103, 58, 183, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-check:active + .btn-link-primary,
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-check:checked + .btn-link-primary {
  background: rgba(103, 58, 183, 0.2);
  color: #673ab7;
  border-color: rgba(103, 58, 183, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(232, 62, 140, 0.2);
  background-color: rgba(232, 62, 140, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-light-primary {
  background: rgba(232, 62, 140, 0.2);
  color: #e83e8c;
  border-color: rgba(232, 62, 140, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-light-primary .material-icons-two-tone {
  background-color: #e83e8c;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-light-primary:hover {
  background: #e83e8c;
  color: #fff;
  border-color: #e83e8c;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-light-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-4] .btn-light-primary:focus {
  background: #e83e8c;
  color: #fff;
  border-color: #e83e8c;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-4] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-4] .btn-light-primary.dropdown-toggle {
  background: #e83e8c;
  color: #fff;
  border-color: #e83e8c;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-check:active + .btn-light-primary,
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-check:checked + .btn-light-primary {
  background: #e83e8c !important;
  color: #fff;
  border-color: #e83e8c;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-link-primary {
  background: transparent;
  color: #e83e8c;
  border-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-link-primary .material-icons-two-tone {
  background-color: #e83e8c;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-link-primary:hover {
  background: rgba(232, 62, 140, 0.2);
  color: #e83e8c;
  border-color: rgba(232, 62, 140, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-link-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-4] .btn-link-primary:focus {
  background: rgba(232, 62, 140, 0.2);
  color: #e83e8c;
  border-color: rgba(232, 62, 140, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-4] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-4] .btn-link-primary.dropdown-toggle {
  background: rgba(232, 62, 140, 0.2);
  color: #e83e8c;
  border-color: rgba(232, 62, 140, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-check:active + .btn-link-primary,
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-check:checked + .btn-link-primary {
  background: rgba(232, 62, 140, 0.2);
  color: #e83e8c;
  border-color: rgba(232, 62, 140, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(244, 66, 54, 0.2);
  background-color: rgba(244, 66, 54, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-light-primary {
  background: rgba(244, 66, 54, 0.2);
  color: #f44236;
  border-color: rgba(244, 66, 54, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-light-primary .material-icons-two-tone {
  background-color: #f44236;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-light-primary:hover {
  background: #f44236;
  color: #fff;
  border-color: #f44236;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-light-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-5] .btn-light-primary:focus {
  background: #f44236;
  color: #fff;
  border-color: #f44236;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-5] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-5] .btn-light-primary.dropdown-toggle {
  background: #f44236;
  color: #fff;
  border-color: #f44236;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-check:active + .btn-light-primary,
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-check:checked + .btn-light-primary {
  background: #f44236 !important;
  color: #fff;
  border-color: #f44236;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-link-primary {
  background: transparent;
  color: #f44236;
  border-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-link-primary .material-icons-two-tone {
  background-color: #f44236;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-link-primary:hover {
  background: rgba(244, 66, 54, 0.2);
  color: #f44236;
  border-color: rgba(244, 66, 54, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-link-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-5] .btn-link-primary:focus {
  background: rgba(244, 66, 54, 0.2);
  color: #f44236;
  border-color: rgba(244, 66, 54, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-5] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-5] .btn-link-primary.dropdown-toggle {
  background: rgba(244, 66, 54, 0.2);
  color: #f44236;
  border-color: rgba(244, 66, 54, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-check:active + .btn-link-primary,
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-check:checked + .btn-link-primary {
  background: rgba(244, 66, 54, 0.2);
  color: #f44236;
  border-color: rgba(244, 66, 54, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(253, 126, 20, 0.2);
  background-color: rgba(253, 126, 20, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-light-primary {
  background: rgba(253, 126, 20, 0.2);
  color: #fd7e14;
  border-color: rgba(253, 126, 20, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-light-primary .material-icons-two-tone {
  background-color: #fd7e14;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-light-primary:hover {
  background: #fd7e14;
  color: #fff;
  border-color: #fd7e14;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-light-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-6] .btn-light-primary:focus {
  background: #fd7e14;
  color: #fff;
  border-color: #fd7e14;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-6] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-6] .btn-light-primary.dropdown-toggle {
  background: #fd7e14;
  color: #fff;
  border-color: #fd7e14;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-check:active + .btn-light-primary,
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-check:checked + .btn-light-primary {
  background: #fd7e14 !important;
  color: #fff;
  border-color: #fd7e14;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-link-primary {
  background: transparent;
  color: #fd7e14;
  border-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-link-primary .material-icons-two-tone {
  background-color: #fd7e14;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-link-primary:hover {
  background: rgba(253, 126, 20, 0.2);
  color: #fd7e14;
  border-color: rgba(253, 126, 20, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-link-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-6] .btn-link-primary:focus {
  background: rgba(253, 126, 20, 0.2);
  color: #fd7e14;
  border-color: rgba(253, 126, 20, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-6] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-6] .btn-link-primary.dropdown-toggle {
  background: rgba(253, 126, 20, 0.2);
  color: #fd7e14;
  border-color: rgba(253, 126, 20, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-check:active + .btn-link-primary,
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-check:checked + .btn-link-primary {
  background: rgba(253, 126, 20, 0.2);
  color: #fd7e14;
  border-color: rgba(253, 126, 20, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(244, 194, 43, 0.2);
  background-color: rgba(244, 194, 43, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-light-primary {
  background: rgba(244, 194, 43, 0.2);
  color: #f4c22b;
  border-color: rgba(244, 194, 43, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-light-primary .material-icons-two-tone {
  background-color: #f4c22b;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-light-primary:hover {
  background: #f4c22b;
  color: #fff;
  border-color: #f4c22b;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-light-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-7] .btn-light-primary:focus {
  background: #f4c22b;
  color: #fff;
  border-color: #f4c22b;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-7] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-7] .btn-light-primary.dropdown-toggle {
  background: #f4c22b;
  color: #fff;
  border-color: #f4c22b;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-check:active + .btn-light-primary,
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-check:checked + .btn-light-primary {
  background: #f4c22b !important;
  color: #fff;
  border-color: #f4c22b;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-link-primary {
  background: transparent;
  color: #f4c22b;
  border-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-link-primary .material-icons-two-tone {
  background-color: #f4c22b;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-link-primary:hover {
  background: rgba(244, 194, 43, 0.2);
  color: #f4c22b;
  border-color: rgba(244, 194, 43, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-link-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-7] .btn-link-primary:focus {
  background: rgba(244, 194, 43, 0.2);
  color: #f4c22b;
  border-color: rgba(244, 194, 43, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-7] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-7] .btn-link-primary.dropdown-toggle {
  background: rgba(244, 194, 43, 0.2);
  color: #f4c22b;
  border-color: rgba(244, 194, 43, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-check:active + .btn-link-primary,
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-check:checked + .btn-link-primary {
  background: rgba(244, 194, 43, 0.2);
  color: #f4c22b;
  border-color: rgba(244, 194, 43, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(29, 233, 182, 0.2);
  background-color: rgba(29, 233, 182, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-light-primary {
  background: rgba(29, 233, 182, 0.2);
  color: #1de9b6;
  border-color: rgba(29, 233, 182, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-light-primary .material-icons-two-tone {
  background-color: #1de9b6;
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-light-primary:hover {
  background: #1de9b6;
  color: #fff;
  border-color: #1de9b6;
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-light-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-8] .btn-light-primary:focus {
  background: #1de9b6;
  color: #fff;
  border-color: #1de9b6;
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-8] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-8] .btn-light-primary.dropdown-toggle {
  background: #1de9b6;
  color: #fff;
  border-color: #1de9b6;
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-check:active + .btn-light-primary,
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-check:checked + .btn-light-primary {
  background: #1de9b6 !important;
  color: #fff;
  border-color: #1de9b6;
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-link-primary {
  background: transparent;
  color: #1de9b6;
  border-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-link-primary .material-icons-two-tone {
  background-color: #1de9b6;
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-link-primary:hover {
  background: rgba(29, 233, 182, 0.2);
  color: #1de9b6;
  border-color: rgba(29, 233, 182, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-link-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-8] .btn-link-primary:focus {
  background: rgba(29, 233, 182, 0.2);
  color: #1de9b6;
  border-color: rgba(29, 233, 182, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-8] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-8] .btn-link-primary.dropdown-toggle {
  background: rgba(29, 233, 182, 0.2);
  color: #1de9b6;
  border-color: rgba(29, 233, 182, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-check:active + .btn-link-primary,
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-check:checked + .btn-link-primary {
  background: rgba(29, 233, 182, 0.2);
  color: #1de9b6;
  border-color: rgba(29, 233, 182, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(0, 128, 128, 0.2);
  background-color: rgba(0, 128, 128, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-light-primary {
  background: rgba(0, 128, 128, 0.2);
  color: #008080;
  border-color: rgba(0, 128, 128, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-light-primary .material-icons-two-tone {
  background-color: #008080;
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-light-primary:hover {
  background: #008080;
  color: #fff;
  border-color: #008080;
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-light-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-9] .btn-light-primary:focus {
  background: #008080;
  color: #fff;
  border-color: #008080;
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-9] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-9] .btn-light-primary.dropdown-toggle {
  background: #008080;
  color: #fff;
  border-color: #008080;
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-check:active + .btn-light-primary,
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-check:checked + .btn-light-primary {
  background: #008080 !important;
  color: #fff;
  border-color: #008080;
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-link-primary {
  background: transparent;
  color: #008080;
  border-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-link-primary .material-icons-two-tone {
  background-color: #008080;
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-link-primary:hover {
  background: rgba(0, 128, 128, 0.2);
  color: #008080;
  border-color: rgba(0, 128, 128, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-link-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-9] .btn-link-primary:focus {
  background: rgba(0, 128, 128, 0.2);
  color: #008080;
  border-color: rgba(0, 128, 128, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-9] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-9] .btn-link-primary.dropdown-toggle {
  background: rgba(0, 128, 128, 0.2);
  color: #008080;
  border-color: rgba(0, 128, 128, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-check:active + .btn-link-primary,
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-check:checked + .btn-link-primary {
  background: rgba(0, 128, 128, 0.2);
  color: #008080;
  border-color: rgba(0, 128, 128, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-10] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(62, 191, 234, 0.2);
  background-color: rgba(62, 191, 234, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-light-primary {
  background: rgba(62, 191, 234, 0.2);
  color: #3ebfea;
  border-color: rgba(62, 191, 234, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-light-primary .material-icons-two-tone {
  background-color: #3ebfea;
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-light-primary:hover {
  background: #3ebfea;
  color: #fff;
  border-color: #3ebfea;
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-light-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-10] .btn-light-primary:focus {
  background: #3ebfea;
  color: #fff;
  border-color: #3ebfea;
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-10] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-10] .btn-light-primary.dropdown-toggle {
  background: #3ebfea;
  color: #fff;
  border-color: #3ebfea;
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-check:active + .btn-light-primary,
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-check:checked + .btn-light-primary {
  background: #3ebfea !important;
  color: #fff;
  border-color: #3ebfea;
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-link-primary {
  background: transparent;
  color: #3ebfea;
  border-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-link-primary .material-icons-two-tone {
  background-color: #3ebfea;
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-link-primary:hover {
  background: rgba(62, 191, 234, 0.2);
  color: #3ebfea;
  border-color: rgba(62, 191, 234, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-link-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-10] .btn-link-primary:focus {
  background: rgba(62, 191, 234, 0.2);
  color: #3ebfea;
  border-color: rgba(62, 191, 234, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-10] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-10] .btn-link-primary.dropdown-toggle {
  background: rgba(62, 191, 234, 0.2);
  color: #3ebfea;
  border-color: rgba(62, 191, 234, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-check:active + .btn-link-primary,
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-check:checked + .btn-link-primary {
  background: rgba(62, 191, 234, 0.2);
  color: #3ebfea;
  border-color: rgba(62, 191, 234, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-11] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(19, 25, 32, 0.2);
  background-color: rgba(19, 25, 32, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-11] .btn-light-primary {
  background: rgba(19, 25, 32, 0.2);
  color: #131920;
  border-color: rgba(19, 25, 32, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-11] .btn-light-primary .material-icons-two-tone {
  background-color: #131920;
}
[data-pc-theme=dark][data-pc-preset=preset-11] .btn-light-primary:hover {
  background: #131920;
  color: #fff;
  border-color: #131920;
}
[data-pc-theme=dark][data-pc-preset=preset-11] .btn-light-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-11] .btn-light-primary:focus {
  background: #131920;
  color: #fff;
  border-color: #131920;
}
[data-pc-theme=dark][data-pc-preset=preset-11] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-11] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-11] .btn-light-primary.dropdown-toggle {
  background: #131920;
  color: #fff;
  border-color: #131920;
}
[data-pc-theme=dark][data-pc-preset=preset-11] .btn-check:active + .btn-light-primary,
[data-pc-theme=dark][data-pc-preset=preset-11] .btn-check:checked + .btn-light-primary {
  background: #131920 !important;
  color: #fff;
  border-color: #131920;
}
[data-pc-theme=dark][data-pc-preset=preset-11] .btn-link-primary {
  background: transparent;
  color: #131920;
  border-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-11] .btn-link-primary .material-icons-two-tone {
  background-color: #131920;
}
[data-pc-theme=dark][data-pc-preset=preset-11] .btn-link-primary:hover {
  background: rgba(19, 25, 32, 0.2);
  color: #131920;
  border-color: rgba(19, 25, 32, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-11] .btn-link-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-11] .btn-link-primary:focus {
  background: rgba(19, 25, 32, 0.2);
  color: #131920;
  border-color: rgba(19, 25, 32, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-11] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-11] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-11] .btn-link-primary.dropdown-toggle {
  background: rgba(19, 25, 32, 0.2);
  color: #131920;
  border-color: rgba(19, 25, 32, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-11] .btn-check:active + .btn-link-primary,
[data-pc-theme=dark][data-pc-preset=preset-11] .btn-check:checked + .btn-link-primary {
  background: rgba(19, 25, 32, 0.2);
  color: #131920;
  border-color: rgba(19, 25, 32, 0.2);
}
[data-pc-theme=dark] .img-thumbnail {
  background: var(--bs-body-bg);
  border-color: var(--bs-body-bg);
}
[data-pc-theme=dark] .card .bg-light,
[data-pc-theme=dark] .card .card-footer.bg-light {
  background-color: rgba(0, 0, 0, 0.15) !important;
}
[data-pc-theme=dark] .card .border {
  border: var(--bs-border-width) var(--bs-border-style) #343a3e !important;
}
[data-pc-theme=dark] .introjs-tooltip,
[data-pc-theme=dark] .notifier,
[data-pc-theme=dark] .datepicker-footer,
[data-pc-theme=dark] .datepicker-picker {
  background-color: #1d2630;
}
[data-pc-theme=dark] .datepicker-cell.focused:not(.selected), [data-pc-theme=dark] .datepicker-cell:not(.disabled):hover {
  background: var(--bs-primary);
  color: #fff;
}
[data-pc-theme=dark] .datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  background-color: rgba(var(--bs-primary), 0.3);
}
[data-pc-theme=dark] .datepicker-cell.range {
  background: #1d2630;
}
[data-pc-theme=dark] .datepicker-controls .btn {
  background: transparent;
  border: transparent;
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .i-main .i-block {
  border-color: rgba(0, 0, 0, 0.3);
}
[data-pc-theme=dark] .material-icons-two-tone:not([class*=text]) {
  background-color: var(--bs-body-color);
}
[data-pc-theme=dark] .flatpickr-calendar {
  background: var(--bs-body-bg);
  box-shadow: none;
}
[data-pc-theme=dark] .flatpickr-calendar .flatpickr-month,
[data-pc-theme=dark] .flatpickr-calendar .flatpickr-months .flatpickr-prev-month,
[data-pc-theme=dark] .flatpickr-calendar .flatpickr-months .flatpickr-next-month {
  color: var(--bs-body-color);
  fill: var(--bs-body-color);
}
[data-pc-theme=dark] .flatpickr-calendar span.flatpickr-weekday {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .flatpickr-calendar .flatpickr-day {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.flatpickr-disabled, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.flatpickr-disabled:hover, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.prevMonthDay, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.nextMonthDay, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.notAllowed, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.notAllowed.prevMonthDay, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.notAllowed.nextMonthDay {
  color: var(--bs-body-color);
  opacity: 0.3;
}
[data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.inRange, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.prevMonthDay.inRange, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.nextMonthDay.inRange, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.today.inRange, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.prevMonthDay.today.inRange, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.nextMonthDay.today.inRange, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day:hover, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.prevMonthDay:hover, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.nextMonthDay:hover, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day:focus, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.prevMonthDay:focus, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.nextMonthDay:focus {
  background: #303f50;
  border-color: #303f50;
  box-shadow: -5px 0 0 #303f50, 5px 0 0 #303f50;
}
[data-pc-theme=dark] .flatpickr-calendar.arrowTop:after, [data-pc-theme=dark] .flatpickr-calendar.arrowTop:before {
  border-bottom-color: var(--bs-body-bg);
}
[data-pc-theme=dark] .flatpickr-calendar.arrowBottom:after, [data-pc-theme=dark] .flatpickr-calendar.arrowBottom:before {
  border-top-color: var(--bs-body-bg);
}
[data-pc-theme=dark] .flatpickr-calendar.hasTime .flatpickr-time {
  border-top: none;
}
[data-pc-theme=dark] .flatpickr-time input,
[data-pc-theme=dark] .flatpickr-time .flatpickr-am-pm {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .flatpickr-time input:focus, [data-pc-theme=dark] .flatpickr-time input:hover,
[data-pc-theme=dark] .flatpickr-time .flatpickr-am-pm:focus,
[data-pc-theme=dark] .flatpickr-time .flatpickr-am-pm:hover {
  background: #303f50;
}
[data-pc-theme=dark] .flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: var(--bs-body-color);
}
[data-pc-theme=dark] .flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: var(--bs-body-color);
}
[data-pc-theme=dark] .choices__inner {
  border-color: rgba(255, 255, 255, 0.15);
  background: transparent;
}
[data-pc-theme=dark] .choices__inner .choices__input {
  background: transparent;
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .choices[data-type*=select-one]:after {
  border-color: var(--bs-body-color) transparent transparent transparent;
}
[data-pc-theme=dark] .choices[data-type*=select-one] .choices__input {
  background-color: transparent;
  border-bottom: none;
}
[data-pc-theme=dark] .choices.is-disabled .choices__inner,
[data-pc-theme=dark] .choices.is-disabled .choices__input {
  background: transparent;
}
[data-pc-theme=dark] .editor-toolbar.fullscreen::before {
  background: linear-gradient(to right, var(--bs-body-bg) 0, var(--bs-body-bg) 100%);
}
[data-pc-theme=dark] .editor-toolbar.fullscreen::after {
  background: linear-gradient(to right, var(--bs-body-bg) 0, var(--bs-body-bg) 100%);
}
[data-pc-theme=dark] .uppy-StatusBar:not([aria-hidden=true]).is-waiting,
[data-pc-theme=dark] .uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
  background: var(--bs-body-bg);
  border-color: #303f50;
}
[data-pc-theme=dark] .uppy-Dashboard-AddFilesPanel {
  background: var(--bs-body-bg);
  background: linear-gradient(0deg, var(--bs-body-bg) 35%, var(--bs-body-bg));
}
[data-pc-theme=dark] .dropzone .dz-preview.dz-image-preview {
  background: var(--bs-body-bg);
}
[data-pc-theme=dark] .editor-preview,
[data-pc-theme=dark] .editor-preview-side {
  background: var(--bs-body-bg);
}
[data-pc-theme=dark] .choices__list--dropdown {
  background: var(--bs-body-bg);
  border-color: #303f50;
}
[data-pc-theme=dark] .noUi-target {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.1);
  border-color: rgba(255, 255, 255, 0.15);
}
[data-pc-theme=dark] .noUi-handle {
  background: var(--bs-body-bg);
  border-color: rgba(255, 255, 255, 0.15);
  box-shadow: none;
}
[data-pc-theme=dark] .noUi-handle:after, [data-pc-theme=dark] .noUi-handle:before {
  background: var(--bs-body-color);
}
[data-pc-theme=dark] .noUi-tooltip {
  background: var(--bs-body-bg);
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .typeahead > ul {
  background: var(--bs-body-bg);
}
[data-pc-theme=dark] .ql-container.ql-snow,
[data-pc-theme=dark] .ql-toolbar.ql-snow {
  border-color: #303f50;
}
[data-pc-theme=dark] .ql-snow .ql-picker {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .ql-snow .ql-stroke {
  stroke: var(--bs-body-color);
}
[data-pc-theme=dark] .ck {
  --ck-color-base-background: var(--bs-body-bg);
  --ck-color-toolbar-background: var(--bs-body-bg);
  --ck-color-base-border: #303f50;
  --ck-color-toolbar-border: #303f50;
  --ck-color-dropdown-panel-border: rgba(0, 0, 0, 0.5);
  --ck-color-button-default-background: transparent;
  --ck-color-text: var(--bs-body-color);
  --ck-color-list-background: var(--bs-body-bg);
  --ck-color-button-default-hover-background: #303f50;
  --ck-color-button-default-active-background: #303f50;
  --ck-color-button-on-active-background: #303f50;
  --ck-color-button-on-background: #303f50;
  --ck-color-button-on-hover-background: #303f50;
  --ck-color-list-button-hover-background: #303f50;
  --ck-color-dropdown-panel-background: var(--bs-body-bg);
  --ck-color-input-background: var(--bs-body-bg);
  --ck-color-panel-background: var(--bs-body-bg);
  --ck-color-panel-border: #303f50;
}
[data-pc-theme=dark] .ck.ck-editor__editable.ck-editor__editable_inline {
  background: var(--bs-body-bg) !important;
  border-color: #303f50;
}
[data-pc-theme=dark] #cke5-inline-demo .ck-content {
  border-color: #303f50;
}
[data-pc-theme=dark] .editor-toolbar,
[data-pc-theme=dark] .CodeMirror {
  background: var(--bs-body-bg);
  color: var(--bs-body-color);
  border-color: #303f50;
}
[data-pc-theme=dark] .editor-toolbar i.separator {
  border-left-color: #303f50;
  border-right-color: #303f50;
}
[data-pc-theme=dark] .editor-toolbar a {
  color: var(--bs-body-color) !important;
}
[data-pc-theme=dark] .editor-toolbar a.active, [data-pc-theme=dark] .editor-toolbar a:hover {
  background: #303f50;
  border-color: #303f50;
}
[data-pc-theme=dark] .dropzone {
  background: #303f50;
}
[data-pc-theme=dark] .uppy-Dashboard-inner,
[data-pc-theme=dark] .uppy-DragDrop-container {
  background: #303f50;
  border-color: #303f50;
}
[data-pc-theme=dark] .uppy-DashboardTab-btn:hover {
  background: #303f50;
}
[data-pc-theme=dark] .uppy-DashboardTab-btn,
[data-pc-theme=dark] .uppy-Dashboard-AddFiles-title {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] [data-uppy-drag-drop-supported=true] .uppy-Dashboard-AddFiles {
  border-color: #303f50;
}
[data-pc-theme=dark] .uppy-StatusBar,
[data-pc-theme=dark] .uppy-DashboardContent-bar {
  border: none;
  color: var(--bs-body-color);
  background: #303f50;
}
[data-pc-theme=dark] .datatable-table,
[data-pc-theme=dark] .table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-border-color: #303f50;
}
[data-pc-theme=dark] .datatable-table thead th,
[data-pc-theme=dark] .table thead th {
  color: var(--bs-body-color);
  background: #303f50;
  border-color: #303f50;
}
[data-pc-theme=dark] .datatable-table > :not(:last-child) > :last-child > *,
[data-pc-theme=dark] .datatable-table td,
[data-pc-theme=dark] .datatable-table th,
[data-pc-theme=dark] .table > :not(:last-child) > :last-child > *,
[data-pc-theme=dark] .table td,
[data-pc-theme=dark] .table th {
  border-color: #303f50;
}
[data-pc-theme=dark] .datatable-table[class*=bg-] > :not(caption) > * > *,
[data-pc-theme=dark] .table[class*=bg-] > :not(caption) > * > * {
  color: #fff;
}
[data-pc-theme=dark] .datatable-table::not([class*="bg-"]) > :not(caption) > * > *,
[data-pc-theme=dark] .table::not([class*="bg-"]) > :not(caption) > * > * {
  background-color: transparent;
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .datatable-table.table-primary,
[data-pc-theme=dark] .table.table-primary {
  --bs-table-color: var(--pc-heading-color);
}
[data-pc-theme=dark] .table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: transparent;
}
[data-pc-theme=dark] .datatable-pagination a {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .datatable-pagination a:hover {
  background: #303f50;
}
[data-pc-theme=dark] .datatable-pagination .active a {
  color: #fff;
}
[data-pc-theme=dark] .datatable-pagination a:hover,
[data-pc-theme=dark] .datatable-pagination button:hover {
  background-color: var(--bs-body-bg);
}
[data-pc-theme=dark] .datatable-pagination .datatable-active a,
[data-pc-theme=dark] .datatable-pagination .datatable-active a:focus,
[data-pc-theme=dark] .datatable-pagination .datatable-active a:hover,
[data-pc-theme=dark] .datatable-pagination .datatable-active button,
[data-pc-theme=dark] .datatable-pagination .datatable-active button:focus,
[data-pc-theme=dark] .datatable-pagination .datatable-active button:hover {
  background-color: var(--bs-body-bg);
}
[data-pc-theme=dark] .datatable-sorter::after {
  border-bottom-color: var(--bs-body-color);
}
[data-pc-theme=dark] .datatable-sorter::before {
  border-top-color: var(--bs-body-color);
}
[data-pc-theme=dark] .dtfh-floatingparentfoot table th {
  background: var(--bs-body-bg);
}
[data-pc-theme=dark] table.dataTable tbody tr > .dtfc-fixed-left,
[data-pc-theme=dark] table.dataTable tbody tr > .dtfc-fixed-right,
[data-pc-theme=dark] div.dataTables_scrollBody > table > tbody tr:first-child th,
[data-pc-theme=dark] div.dataTables_scrollBody > table > tbody tr:first-child td,
[data-pc-theme=dark] .datatable-table.dataTable[class*=table-] thead th,
[data-pc-theme=dark] .table.dataTable[class*=table-] thead th,
[data-pc-theme=dark] table.datatable.fixedHeader-floating,
[data-pc-theme=dark] table.datatable.fixedHeader-locked,
[data-pc-theme=dark] div.DTFC_LeftHeadWrapper table,
[data-pc-theme=dark] div.DTFC_RightHeadWrapper table,
[data-pc-theme=dark] table.DTFC_Cloned tr {
  background: var(--bs-body-bg);
}
[data-pc-theme=dark] table.dataTable thead tr > .dtfc-fixed-left,
[data-pc-theme=dark] table.dataTable thead tr > .dtfc-fixed-right,
[data-pc-theme=dark] table.dataTable tfoot tr > .dtfc-fixed-left,
[data-pc-theme=dark] table.dataTable tfoot tr > .dtfc-fixed-right,
[data-pc-theme=dark] .table.datatable[class*=table-] thead th {
  background: #303f50;
}
[data-pc-theme=dark] .table-bordered > :not(caption) > * {
  border-width: 0px;
}
[data-pc-theme=dark] table.datatable > tbody > tr.child ul.dtr-details > li {
  border-bottom-color: #303f50;
}
[data-pc-theme=dark] .datatable-pagination a,
[data-pc-theme=dark] .datatable-pagination button {
  background-color: transparent;
}
[data-pc-theme=dark] .datatable-pagination .datatable-active a, [data-pc-theme=dark] .datatable-pagination .datatable-active a:focus, [data-pc-theme=dark] .datatable-pagination .datatable-active a:hover,
[data-pc-theme=dark] .datatable-pagination .datatable-active button,
[data-pc-theme=dark] .datatable-pagination .datatable-active button:focus,
[data-pc-theme=dark] .datatable-pagination .datatable-active button:hover {
  background-color: #263240;
}
[data-pc-theme=dark] .apexcharts-legend-text {
  color: var(--bs-body-color) !important;
}
[data-pc-theme=dark] text {
  fill: var(--bs-body-color) !important;
}
[data-pc-theme=dark] .apexcharts-datalabels text,
[data-pc-theme=dark] .apexcharts-data-labels text {
  fill: #fff !important;
}
[data-pc-theme=dark] .apexcharts-canvas line {
  stroke: transparent !important;
}
[data-pc-theme=dark] .apexcharts-menu {
  background: var(--bs-body-bg);
  border-color: #303f50;
}
[data-pc-theme=dark] .apexcharts-theme-light .apexcharts-menu-item:hover {
  background: var(--bs-card-bg);
}
[data-pc-theme=dark] .apexcharts-theme-light .apexcharts-menu-icon:hover svg,
[data-pc-theme=dark] .apexcharts-theme-light .apexcharts-reset-icon:hover svg,
[data-pc-theme=dark] .apexcharts-theme-light .apexcharts-selection-icon:not(.apexcharts-selected):hover svg,
[data-pc-theme=dark] .apexcharts-theme-light .apexcharts-zoom-icon:not(.apexcharts-selected):hover svg,
[data-pc-theme=dark] .apexcharts-theme-light .apexcharts-zoomin-icon:hover svg,
[data-pc-theme=dark] .apexcharts-theme-light .apexcharts-zoomout-icon:hover svg {
  fill: var(--bs-body-color);
}
[data-pc-theme=dark] .apexcharts-tooltip.apexcharts-theme-light {
  background: var(--bs-body-bg);
  border-color: #303f50;
}
[data-pc-theme=dark] .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: var(--bs-body-bg);
  border-bottom-color: #303f50;
}
[data-pc-theme=dark] .apexcharts-xaxistooltip,
[data-pc-theme=dark] .apexcharts-yaxistooltip {
  background: var(--bs-body-bg);
  border-color: #303f50;
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .apexcharts-xaxistooltip-bottom::after, [data-pc-theme=dark] .apexcharts-xaxistooltip-bottom::before {
  border-bottom-color: var(--bs-body-bg);
}
[data-pc-theme=dark] .fc .fc-list-sticky .fc-list-day > *,
[data-pc-theme=dark] .fc .fc-scrollgrid-section-sticky > *,
[data-pc-theme=dark] .auth-main .auth-wrapper.v3,
[data-pc-theme=dark] .auth-main .auth-wrapper.v2 {
  background: #1b232d;
}
[data-pc-theme=dark] .table-bordered td,
[data-pc-theme=dark] .table-bordered th,
[data-pc-theme=dark] .table-bordered {
  border-color: #303f50;
}
[data-pc-theme=dark] .contact-form.bg-white {
  background: #1b232d !important;
}
[data-pc-theme=dark] .fc-event.event-primary {
  background: rgba(13, 110, 253, 0.2) !important;
  color: #0d6efd !important;
}
[data-pc-theme=dark] .fc-event.event-primary.fc-h-event .fc-event-main {
  color: #0d6efd;
}
[data-pc-theme=dark] .fc-event.event-primary.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-primary.fc-h-event:hover {
  background: #0d6efd;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-secondary {
  background: rgba(91, 107, 121, 0.2) !important;
  color: #5b6b79 !important;
}
[data-pc-theme=dark] .fc-event.event-secondary.fc-h-event .fc-event-main {
  color: #5b6b79;
}
[data-pc-theme=dark] .fc-event.event-secondary.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-secondary.fc-h-event:hover {
  background: #5b6b79;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-success {
  background: rgba(25, 135, 84, 0.2) !important;
  color: #198754 !important;
}
[data-pc-theme=dark] .fc-event.event-success.fc-h-event .fc-event-main {
  color: #198754;
}
[data-pc-theme=dark] .fc-event.event-success.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-success.fc-h-event:hover {
  background: #198754;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-info {
  background: rgba(13, 202, 240, 0.2) !important;
  color: #0dcaf0 !important;
}
[data-pc-theme=dark] .fc-event.event-info.fc-h-event .fc-event-main {
  color: #0dcaf0;
}
[data-pc-theme=dark] .fc-event.event-info.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-info.fc-h-event:hover {
  background: #0dcaf0;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-warning {
  background: rgba(255, 193, 7, 0.2) !important;
  color: #ffc107 !important;
}
[data-pc-theme=dark] .fc-event.event-warning.fc-h-event .fc-event-main {
  color: #ffc107;
}
[data-pc-theme=dark] .fc-event.event-warning.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-warning.fc-h-event:hover {
  background: #ffc107;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-danger {
  background: rgba(220, 53, 69, 0.2) !important;
  color: #dc3545 !important;
}
[data-pc-theme=dark] .fc-event.event-danger.fc-h-event .fc-event-main {
  color: #dc3545;
}
[data-pc-theme=dark] .fc-event.event-danger.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-danger.fc-h-event:hover {
  background: #dc3545;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-light {
  background: rgba(248, 249, 250, 0.2) !important;
  color: #f8f9fa !important;
}
[data-pc-theme=dark] .fc-event.event-light.fc-h-event .fc-event-main {
  color: #f8f9fa;
}
[data-pc-theme=dark] .fc-event.event-light.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-light.fc-h-event:hover {
  background: #f8f9fa;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-dark {
  background: rgba(19, 25, 32, 0.2) !important;
  color: #131920 !important;
}
[data-pc-theme=dark] .fc-event.event-dark.fc-h-event .fc-event-main {
  color: #131920;
}
[data-pc-theme=dark] .fc-event.event-dark.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-dark.fc-h-event:hover {
  background: #131920;
  color: #fff;
}
[data-pc-theme=dark] .btns-gallery .btn-light-primary:not(:hover), [data-pc-theme=dark] .btns-gallery .btn-light-primary:not(:focus), [data-pc-theme=dark] .btns-gallery .btn-light-primary:not(:active), [data-pc-theme=dark] .btns-gallery .btn-light-primary:not(.active) {
  background: transparent !important;
  border-color: transparent;
  color: #4c6480;
}
[data-pc-theme=dark] .btns-gallery .btn-light-primary.active {
  background: var(--bs-primary) !important;
}
[data-pc-theme=dark] .jvm-container path {
  fill: #303f50;
}
[data-pc-theme=dark] .btn-light-primary .material-icons-two-tone {
  background-color: #0d6efd;
}
[data-pc-theme=dark] .btn-light-primary:hover {
  background: #0d6efd;
  border-color: #0d6efd;
}
[data-pc-theme=dark] .btn-light-primary:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-primary.focus, [data-pc-theme=dark] .btn-light-primary:focus {
  background: #0d6efd;
  border-color: #0d6efd;
}
[data-pc-theme=dark] .btn-light-primary.focus .material-icons-two-tone, [data-pc-theme=dark] .btn-light-primary:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark] .btn-light-primary.dropdown-toggle {
  background: #0d6efd;
  border-color: #0d6efd;
}
[data-pc-theme=dark] .btn-light-primary:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-theme=dark] .btn-light-primary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-theme=dark] .btn-light-primary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-secondary .material-icons-two-tone {
  background-color: #5b6b79;
}
[data-pc-theme=dark] .btn-light-secondary:hover {
  background: #5b6b79;
  border-color: #5b6b79;
}
[data-pc-theme=dark] .btn-light-secondary:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-secondary.focus, [data-pc-theme=dark] .btn-light-secondary:focus {
  background: #5b6b79;
  border-color: #5b6b79;
}
[data-pc-theme=dark] .btn-light-secondary.focus .material-icons-two-tone, [data-pc-theme=dark] .btn-light-secondary:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-secondary:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-light-secondary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark] .btn-light-secondary.dropdown-toggle {
  background: #5b6b79;
  border-color: #5b6b79;
}
[data-pc-theme=dark] .btn-light-secondary:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-theme=dark] .btn-light-secondary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-theme=dark] .btn-light-secondary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-success .material-icons-two-tone {
  background-color: #198754;
}
[data-pc-theme=dark] .btn-light-success:hover {
  background: #198754;
  border-color: #198754;
}
[data-pc-theme=dark] .btn-light-success:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-success.focus, [data-pc-theme=dark] .btn-light-success:focus {
  background: #198754;
  border-color: #198754;
}
[data-pc-theme=dark] .btn-light-success.focus .material-icons-two-tone, [data-pc-theme=dark] .btn-light-success:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-success:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-light-success:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark] .btn-light-success.dropdown-toggle {
  background: #198754;
  border-color: #198754;
}
[data-pc-theme=dark] .btn-light-success:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-theme=dark] .btn-light-success:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-theme=dark] .btn-light-success.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-info .material-icons-two-tone {
  background-color: #0dcaf0;
}
[data-pc-theme=dark] .btn-light-info:hover {
  background: #0dcaf0;
  border-color: #0dcaf0;
}
[data-pc-theme=dark] .btn-light-info:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-info.focus, [data-pc-theme=dark] .btn-light-info:focus {
  background: #0dcaf0;
  border-color: #0dcaf0;
}
[data-pc-theme=dark] .btn-light-info.focus .material-icons-two-tone, [data-pc-theme=dark] .btn-light-info:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-info:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-light-info:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark] .btn-light-info.dropdown-toggle {
  background: #0dcaf0;
  border-color: #0dcaf0;
}
[data-pc-theme=dark] .btn-light-info:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-theme=dark] .btn-light-info:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-theme=dark] .btn-light-info.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-warning .material-icons-two-tone {
  background-color: #ffc107;
}
[data-pc-theme=dark] .btn-light-warning:hover {
  background: #ffc107;
  border-color: #ffc107;
}
[data-pc-theme=dark] .btn-light-warning:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-warning.focus, [data-pc-theme=dark] .btn-light-warning:focus {
  background: #ffc107;
  border-color: #ffc107;
}
[data-pc-theme=dark] .btn-light-warning.focus .material-icons-two-tone, [data-pc-theme=dark] .btn-light-warning:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-warning:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-light-warning:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark] .btn-light-warning.dropdown-toggle {
  background: #ffc107;
  border-color: #ffc107;
}
[data-pc-theme=dark] .btn-light-warning:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-theme=dark] .btn-light-warning:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-theme=dark] .btn-light-warning.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-danger .material-icons-two-tone {
  background-color: #dc3545;
}
[data-pc-theme=dark] .btn-light-danger:hover {
  background: #dc3545;
  border-color: #dc3545;
}
[data-pc-theme=dark] .btn-light-danger:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-danger.focus, [data-pc-theme=dark] .btn-light-danger:focus {
  background: #dc3545;
  border-color: #dc3545;
}
[data-pc-theme=dark] .btn-light-danger.focus .material-icons-two-tone, [data-pc-theme=dark] .btn-light-danger:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-danger:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-light-danger:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark] .btn-light-danger.dropdown-toggle {
  background: #dc3545;
  border-color: #dc3545;
}
[data-pc-theme=dark] .btn-light-danger:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-theme=dark] .btn-light-danger:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-theme=dark] .btn-light-danger.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-light .material-icons-two-tone {
  background-color: #f8f9fa;
}
[data-pc-theme=dark] .btn-light-light:hover {
  background: #f8f9fa;
  border-color: #f8f9fa;
}
[data-pc-theme=dark] .btn-light-light:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-light.focus, [data-pc-theme=dark] .btn-light-light:focus {
  background: #f8f9fa;
  border-color: #f8f9fa;
}
[data-pc-theme=dark] .btn-light-light.focus .material-icons-two-tone, [data-pc-theme=dark] .btn-light-light:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-light:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-light-light:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark] .btn-light-light.dropdown-toggle {
  background: #f8f9fa;
  border-color: #f8f9fa;
}
[data-pc-theme=dark] .btn-light-light:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-theme=dark] .btn-light-light:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-theme=dark] .btn-light-light.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-dark .material-icons-two-tone {
  background-color: #131920;
}
[data-pc-theme=dark] .btn-light-dark:hover {
  background: #131920;
  border-color: #131920;
}
[data-pc-theme=dark] .btn-light-dark:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-dark.focus, [data-pc-theme=dark] .btn-light-dark:focus {
  background: #131920;
  border-color: #131920;
}
[data-pc-theme=dark] .btn-light-dark.focus .material-icons-two-tone, [data-pc-theme=dark] .btn-light-dark:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .btn-light-dark:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-light-dark:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark] .btn-light-dark.dropdown-toggle {
  background: #131920;
  border-color: #131920;
}
[data-pc-theme=dark] .btn-light-dark:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-theme=dark] .btn-light-dark:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-theme=dark] .btn-light-dark.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-theme=dark] .mail-wrapper .mail-table tr:not(.unread) {
  background: rgba(0, 0, 0, 0.3);
}
[data-pc-theme=dark] .product-card .btn-prod-card {
  border-color: rgba(255, 255, 255, 0.4);
}
[data-pc-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
[data-pc-theme=dark] .uppy-DragDrop-label,
[data-pc-theme=dark] .uppy-StatusBar-content,
[data-pc-theme=dark] .uppy-Root {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .playbutton-wrapper [data-action],
[data-pc-theme=dark] .tns-outer [data-action] {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .slider.slider-disabled .slider-track,
[data-pc-theme=dark] .slider-track {
  background: var(--bs-body-bg);
}
[data-pc-theme=dark] div:where(.swal2-container) .swal2-range {
  background: var(--bs-body-bg) !important;
}
[data-pc-theme=dark] .modal-body .bd-example-row {
  background: var(--bs-body-bg);
}
[data-pc-theme=dark] .auth-main .auth-wrapper .saprator:after {
  background: rgba(255, 255, 255, 0.2);
}
[data-pc-theme=dark].component-page .footer .footer-link a:not(:hover) {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .text-bg-light.text-dark,
[data-pc-theme=dark] .nav-action-card .text-dark {
  color: #131920 !important;
}
[data-pc-theme=dark] .pc-sidebar-color {
  display: none;
}
[data-pc-theme=dark] .text-bg-warning.text-dark,
[data-pc-theme=dark] .prod-action-links a:not(:hover) {
  color: #fff !important;
}
[data-pc-theme=dark] .material-icons-two-tone.text-secondary {
  background-color: var(--bs-body-color) !important;
}
[data-pc-theme=dark] .card.text-white[class*=bg-] .card-header {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
[data-pc-theme=dark] .card.bg-light .card-title,
[data-pc-theme=dark] .card.bg-light .card-text,
[data-pc-theme=dark] .card.bg-light .card-header {
  color: #131920 !important;
}
[data-pc-theme=dark] .btn-sm.btn-light-dark:hover {
  background: #1b232d;
  border-color: #1b232d;
}
[data-pc-theme=dark] .btn-link-hover-primary:not(:hover),
[data-pc-theme=dark] .link-secondary,
[data-pc-theme=dark] .checkout-tabs .nav-item .nav-link,
[data-pc-theme=dark] .btn-pc-default:not(:hover) {
  color: var(--bs-body-color) !important;
}
[data-pc-theme=dark].layout-nested .pc-submenu-list-wrapper {
  background: var(--pc-sidebar-background);
}